import React from "react";

import { ExtendedPatient } from "@aspire/common";
import { Link as LinkIcon } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { DialogProps } from "@toolpad/core/useDialogs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { v4 } from "uuid";
import { api } from "~/api.js";
import { LoggedInUserContext } from "~/Contexts.js";
import { routeFns } from "~/routes.js";
import { Banner, BannerList } from "../../Banner/Banner.js";
import { PopupDialog } from "../../PopupDialog/PopupDialog.js";
import { PopupDialogTitle } from "../../PopupDialog/PopupDialogTitle.js";

export const CreateLinkButton = ({
  patient,
}: {
  patient: Pick<ExtendedPatient, "nhsNumber">;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const nhsNumber = patient.nhsNumber!;
  const userContext = React.useContext(LoggedInUserContext);
  const enabledRio =
    userContext?.user?.sessionOrganisationConfiguration
      ?.rioInstancesEnabled[0]!;

  return (
    <Button
      variant="outlined"
      startIcon={<LinkIcon />}
      sx={{ minWidth: "max-content" }}
      onClick={async () => {
        const token = v4();

        await api.rio.clickThrough.createAndClaimToken({
          externalSystemType: "rio",
          externalSystemId: enabledRio.instanceId,
          externalPatientIdType: "nhs.number",
          externalPatientIdValue: nhsNumber,
          token: token,
        });

        navigate(routeFns.patientCreateFromExternalId(token));
      }}
    >
      {t("components.externalPatientLink.createLinkButton.linkWithRio")}
    </Button>
  );
};

export const LinkToPdsButton = ({
  patient,
}: {
  patient: Pick<ExtendedPatient, "id">;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button
      variant="outlined"
      startIcon={<LinkIcon />}
      sx={{ minWidth: "max-content" }}
      onClick={async () => {
        navigate(routeFns.patientLinkToPds(patient.id));
      }}
    >
      {t("components.externalPatientLink.createLinkButton.linkWithPds")}
    </Button>
  );
};
export function CreateLinkDialog({ open, onClose }: DialogProps) {
  const { t } = useTranslation();

  return (
    <PopupDialog open={open} onClose={() => onClose()}>
      <PopupDialogTitle
        titleText={t(
          "components.externalPatientLink.createLinkButton.dialog.title",
        )}
        closeDialog={onClose}
      />

      <Banner
        bannerType={BannerList.WARNING}
        body={t(
          "components.externalPatientLink.createLinkButton.dialog.body",
        ).split("\n")}
      />

      <Stack
        sx={{
          display: "flex",
          marginTop: "2rem",
          justifyContent: "centre",
          alignItems: "center",
        }}
      >
        <Button variant="contained" onClick={() => onClose()}>
          Close
        </Button>
      </Stack>
    </PopupDialog>
  );
}
