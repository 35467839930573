import { FormContextData } from "@aspire/common";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Banner,
  BannerList,
  LoadingSpinner,
  PopupDialog,
} from "~/components/design-system/index.js";
import { useMhaStatus, usePatientTimeline } from "~/hooks/apiCalls.js";
import { LoggedInUserContext } from "../../Contexts.js";
import {
  RenderPatientBannerAndEditStatus,
  userCanEditMhaStatus,
} from "./EditMhaStatusPage.js";

export function EditMhaStatusDialog({
  hasFormWithApplicationOrMedRec,
  formContext,
  reloadFormContext,
}: {
  hasFormWithApplicationOrMedRec: boolean;
  formContext: FormContextData;
  reloadFormContext: () => void;
}) {
  const { patientId } = useParams();

  const { patientTimeline } = usePatientTimeline({
    patientId: patientId,
  });
  const { mhaStatus } = useMhaStatus({ patientId: patientId });

  const userContext = useContext(LoggedInUserContext);
  const showEditMhaStatus = userCanEditMhaStatus(userContext?.user);

  return (
    <PopupDialog open={true}>
      {!showEditMhaStatus ? (
        <Banner
          bannerType={BannerList.ERROR}
          title={"Incorrect permissions to edit MHA status page"}
        />
      ) : patientTimeline && mhaStatus ? (
        <RenderPatientBannerAndEditStatus
          patientTimeline={patientTimeline}
          mhaStatus={mhaStatus}
          patientId={patientTimeline.patient.id}
          hasFormWithApplicationOrMedRec={hasFormWithApplicationOrMedRec}
          formContext={formContext}
          isScrutiny={true}
          reloadFormContext={reloadFormContext}
        />
      ) : (
        <LoadingSpinner />
      )}
    </PopupDialog>
  );
}
