import {
  ExtendedPatient,
  FormBuilderField,
  FormDraft,
  FormPartTemplate,
  getBaseFormTemplate,
  GetFormDraftResponse,
  isGuestUser,
  MhFormContext,
} from "@aspire/common";
import { css } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { debounce } from "lodash-es";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Schema } from "yup";
import {
  Banner,
  BannerList,
  Button,
  ButtonIcon,
  FormTitle,
  HelperText,
  ReadOnlyContent,
  Stepper,
} from "~/components/design-system/index.js";
import {
  AmhpLocalAuthorityFormField,
  ApprovedClinicianConfirmationCheckboxFormField,
  BranchFormField,
  ConfirmationCheckboxFormField,
  DateFormField,
  DateTimeFormField,
  DropdownFormField,
  FormFooterSection,
  HospitalPicker,
  MultiSelectFormField,
  NameAddressFormField,
  PractitionerFormField,
  Section12ConfirmationCheckboxFormField,
  StandardLocalAuthorityFormField,
  TextboxFormField,
  TimeFormField,
} from "~/components/form/index.js";
import { PatientBanner } from "~/components/layout/index.js";
import { Container } from "~/components/layout/styleWrappers.js";
import { api, apiHooks } from "../../api.js";
import { AutoSaveAction } from "../../components/types/form.js";
import { LoggedInUserContext } from "../../Contexts.js";
import { WithPatient } from "../../hoc/WithPatient.js";
import { usePatientTimeline } from "../../hooks/apiCalls.js";
import {
  autoSaveStateReducer,
  initalAutoSaveState,
} from "../../reducers/autoSaveReducer.js";
import { routeFns } from "../../routes.js";
import { DefaultPageProps } from "../defaultProps.js";
import { FieldProps } from "../FieldProps.js";
import { BannerDialog } from "../FormProgressPage/helpers/BannerDialog.js";
import { useScrollFirstErrorIntoViewEffect } from "../helpers/UseScrollFirstErrorIntoViewEffect/UseScrollFirstErrorIntoViewEffect.js";
import { EditPatientDialog } from "./EditPatientDialog.js";
import {
  comparePatientDemographics,
  PatientDemographicsOutdatedBanner,
} from "./helpers/comparePatientDemographics.js";

export function renderField<Data extends object>(
  field: FormBuilderField<Data>,
  fieldProps: FieldProps<Data>,
) {
  switch (field.type) {
    case "separator":
      return (
        <Box
          sx={{
            mt: "2rem",
            mb: "2rem",
            width: "100%",
            height: 2,
            backgroundColor: "gray",
          }}
        />
      );
    case "label":
      return field.label.map((label) => (
        <Box sx={{ mt: 1, mb: 3 }}>
          {field.labelType === "subtitle" ? (
            <div
              css={css`
                font-size: larger;
                margin-top: -1em;
              `}
            >
              {label}
            </div>
          ) : (
            <>
              {field.showStaticLabel ? (
                <Box>
                  <Typography
                    component={field.showAsBulletPoint ? "ul" : "p"}
                    sx={{
                      fontSize: (theme) => theme.spacing(1.85),
                      color: (theme) => theme.palette.common.black,
                      ml: field.indent ? field.indent : 0,
                      fontWeight: "bold",
                    }}
                  >
                    {field.showAsBulletPoint ? <li>{label}</li> : label}
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <FormTitle
                    useReducedTopPadding
                    hasContainerMarginBottom={false}
                    hasTitleBottomMargin={false}
                    titleText={label}
                    showBorder={true}
                  />
                </Box>
              )}
            </>
          )}
        </Box>
      ));
    case "readonly":
      return (
        <>
          <Box sx={{ mb: 3 }}>
            <ReadOnlyContent
              label={field.label}
              isActive={true}
              content={field.textFn(
                fieldProps.values,
                fieldProps.context as MhFormContext,
              )}
            />
            {field.afterLabel && <HelperText subtext={field.afterLabel} />}
            {field.isReadOnlyPatientInfo && <OpenPatientEditDialog />}
          </Box>
        </>
      );

    case "hospital-picker":
      return <HospitalPicker field={field} fieldProps={fieldProps} />;

    case "practitioner":
      return <PractitionerFormField field={field} fieldProps={fieldProps} />;

    case "name-address":
      return <NameAddressFormField field={field} fieldProps={fieldProps} />;

    case "local-authority-picker":
      return (
        <StandardLocalAuthorityFormField
          fieldProps={fieldProps}
          field={field}
        />
      );

    case "amph-local-authority-picker":
      return (
        <AmhpLocalAuthorityFormField field={field} fieldProps={fieldProps} />
      );

    case "textbox":
      return <TextboxFormField field={field} fieldProps={fieldProps} />;

    case "multi-select":
      return <MultiSelectFormField field={field} fieldProps={fieldProps} />;

    case "confirmation-checkbox":
      return (
        <ConfirmationCheckboxFormField field={field} fieldProps={fieldProps} />
      );

    case "section-12-confirmation-checkbox":
      return (
        <Section12ConfirmationCheckboxFormField
          field={field}
          fieldProps={fieldProps}
        />
      );

    case "approved-clinician-confirmation-checkbox":
      return (
        <ApprovedClinicianConfirmationCheckboxFormField
          field={field}
          fieldProps={fieldProps}
        />
      );

    case "branch":
      return <BranchFormField field={field} fieldProps={fieldProps} />;

    case "date-time":
      return <DateTimeFormField field={field} fieldProps={fieldProps} />;

    case "dropdown":
      return <DropdownFormField field={field} fieldProps={fieldProps} />;

    case "date":
      return <DateFormField field={field} fieldProps={fieldProps} />;

    case "time":
      return <TimeFormField field={field} fieldProps={fieldProps} />;
  }
}

export function safeCast(schema: Schema, values: any) {
  try {
    return schema.cast(values);
  } catch (e) {
    return values;
  }
}

export function ThalamosForm({
  formDraft,
  fieldProps,
  partTemplate,
  dispatchAutoSaveState,
  submitCount,
  isSubmitting,
  isAutoSaving,
  redirectToFormPage,
}: {
  partTemplate: FormPartTemplate<any>;
  fieldProps: FieldProps<any>;
  formDraft: FormDraft;
  dispatchAutoSaveState: (state: AutoSaveAction) => void;
  submitCount: number;
  isSubmitting: boolean;
  isAutoSaving: boolean;
  redirectToFormPage: () => void;
}) {
  // Debounced auto-save action
  const autoSave = useCallback(
    debounce(async (fieldProps: FieldProps<any>) => {
      try {
        const result = await api.drafts.updateData(
          formDraft.id,
          safeCast(fieldProps.validationSchema, fieldProps.values),
        );

        if (result.status === 204) {
          dispatchAutoSaveState("success");
        } else if (result.status === 404) {
          redirectToFormPage();
        } else {
          dispatchAutoSaveState("error");
        }
      } catch (error) {
        // TODO: log error to datadog?
        dispatchAutoSaveState("error");
      }
    }, 1000),
    [formDraft.id, dispatchAutoSaveState],
  );

  // Trigger auto-save on field change
  useEffect(() => {
    if (!isAutoSaving) dispatchAutoSaveState("loading");
    autoSave(fieldProps);
  }, [autoSave, fieldProps.values]);

  // Auto-scroll to the first form error on submit
  useScrollFirstErrorIntoViewEffect([submitCount, isSubmitting]);

  return (
    <>
      {partTemplate.formBuilderFields.map((field) => (
        <>{renderField(field, fieldProps)}</>
      ))}
    </>
  );
}

function PatientDataValidatorAndUpdater({
  patient,
  values,
  previousPartsFormData,
  setValues,
  patientEditParamSuccess,
}: {
  patient: ExtendedPatient;
  values: any;
  setValues: (values: any) => void;
  previousPartsFormData: any[];
  patientEditParamSuccess: boolean;
}) {
  const { t } = useTranslation();
  const [showAutoUpdateDialog, setShowAutoUpdateDialog] = useState(false);

  const isFirstFormPart = !previousPartsFormData.length;

  const { newPatientDemographics, patientDemographicsMatch } =
    comparePatientDemographics(patient, values);

  // If patient data is out of date on part 1 of a form, update it and show a dialog
  useEffect(() => {
    if (isFirstFormPart && !patientDemographicsMatch) {
      setValues({ ...values, patient: newPatientDemographics });
      setShowAutoUpdateDialog(true);
    }
  }, [
    values,
    setValues,
    isFirstFormPart,
    newPatientDemographics,
    patientDemographicsMatch,
  ]);

  return (
    <>
      {showAutoUpdateDialog && !patientEditParamSuccess && (
        <BannerDialog
          title={t("pages.formDraftCompletionPage.patientDetailsEdited")}
          bannerType={BannerList.INFO}
          message={t(
            "pages.formDraftCompletionPage.patientDetailsEditedBanner",
          )}
          onClose={() => setShowAutoUpdateDialog(false)}
        />
      )}
    </>
  );
}

export function FormDraftPageInner({
  formDraft,
  patient,
  refetchDraft,
}: {
  formDraft: GetFormDraftResponse<any>;
  patient: ExtendedPatient;
  refetchDraft: () => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [autoSaveState, dispatchAutoSaveState] = useReducer(
    autoSaveStateReducer,
    initalAutoSaveState,
  );

  const patientId = patient.id;

  const { patientTimeline, reloadPatientTimeline } = usePatientTimeline({
    patientId: patientId,
  });

  const nhsNumber = patient.nhsNumber;

  const [activeStep] = React.useState(0);

  const template = getBaseFormTemplate(formDraft)!;

  const redirectToFormPage = useCallback(() => {
    navigate(routeFns.formContextPage(formDraft.formContextId, patient.id));
  }, [navigate, formDraft.formContextId, patient.id]);

  const partTemplate = template.parts[formDraft.part - 1]!;

  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const patientEditParam = params.get("patientedit");
  const patientEditParamSuccess = params.get("patienteditsuccess");

  const requestedByUserName = formDraft.requestedByUserName || "";
  const requestedByTeamName = formDraft.requestedByTeamName
    ? `(${formDraft.requestedByTeamName})`
    : "";

  const createdByName = `${requestedByUserName}  ${requestedByTeamName}`;

  return patient ? (
    <div>
      <PatientBanner
        patient={patient}
        patientTimeline={patientTimeline}
        nhsNumber={nhsNumber ?? undefined}
        reloadPatientTimeline={reloadPatientTimeline}
      />
      <Box sx={{ my: 4 }}>
        <Stepper
          steps={[
            t("pages.formDraftFlow.standardFlow.stepOne"),
            t("pages.formDraftFlow.standardFlow.stepTwo"),
            t("pages.formDraftFlow.standardFlow.stepThree"),
          ]}
          activeStep={activeStep}
        />
      </Box>

      {formDraft.requestedReasons ? (
        <Box sx={{ mb: 2 }}>
          <Banner
            title={`Section 15 Amend Requested`}
            bannerType={BannerList.WARNING}
            body={[
              `Requested by ${createdByName}`,
              `Reason(s): ${formDraft.requestedReasons
                .map((r) => r.label)
                .join(", ")}.`,
              `Description: ${formDraft.requestedAdditionalInfo}`,
            ]}
          />
        </Box>
      ) : null}

      {formDraft.previousPartsFormData.length > 0 && (
        <PatientDemographicsOutdatedBanner
          patient={patient}
          currentValues={formDraft.previousPartsFormData[0].data}
        />
      )}
      <Box display="flex" justifyContent="space-between">
        <FormTitle
          useReducedTopPadding={true}
          hasTitleBottomMargin={!!template.section}
          titleText={`${template.formName}${template.description && `:`} ${
            template.description
          }`}
          subtitleText={`${
            template.section ? `Section ${template.section}` : ""
          }`}
        />
      </Box>

      <Formik
        initialValues={formDraft.data}
        onSubmit={async (values: any, formikHelpers: FormikHelpers<any>) => {
          await api.drafts.updateData(
            formDraft.id,
            safeCast(partTemplate.dataSchema, values),
          );
          navigate(routeFns.formDraftsSign(formDraft.id, patient.id));
        }}
        validationSchema={partTemplate.dataSchema}
      >
        {({
          values,
          errors,
          touched,
          setValues,
          handleBlur,
          isSubmitting,
          setFieldTouched,
          submitForm,
          submitCount,
          /* and other goodies */
        }) => {
          const mhFormContext: MhFormContext = {
            previousPartsFormData: formDraft.previousPartsFormData,
            previousForms: formDraft.formContext.forms,
            linkedForms: formDraft.linkedForms,
          };

          return (
            <>
              <Box
                data-testid={`form:${formDraft.formTemplate.id}`}
                data-formid={formDraft.formId}
                data-formcontextid={formDraft.formContextId}
              >
                <Container>
                  <PatientDataValidatorAndUpdater
                    patient={patient}
                    values={values}
                    setValues={setValues}
                    previousPartsFormData={formDraft.previousPartsFormData}
                    patientEditParamSuccess={!!patientEditParamSuccess}
                  />
                  <ThalamosForm
                    partTemplate={partTemplate}
                    fieldProps={{
                      validationSchema: partTemplate.dataSchema,
                      context: mhFormContext,
                      values,
                      setValues,
                      errors,
                      touched,
                      handleBlur,
                      setFieldTouched,
                    }}
                    submitCount={submitCount}
                    isSubmitting={isSubmitting}
                    isAutoSaving={autoSaveState.isLoading}
                    dispatchAutoSaveState={dispatchAutoSaveState}
                    formDraft={formDraft}
                    redirectToFormPage={redirectToFormPage}
                  />
                </Container>
                <FormFooterSection
                  isSticky={true}
                  disableSubmit={isSubmitting}
                  saveLabel={t("buttonLabels.sign")}
                  discardLabel={t("buttonLabels.goBack")}
                  autoSaveState={autoSaveState}
                  onSave={submitForm}
                  onCancel={() => {
                    navigate(
                      routeFns.formContextPage(
                        formDraft.formContextId,
                        formDraft.patientId,
                      ),
                    );
                  }}
                />
              </Box>
            </>
          );
        }}
      </Formik>
      {!!patientEditParam && <EditPatientDialog refetchDraft={refetchDraft} />}
    </div>
  ) : (
    <div></div>
  );
}

export function FormDraftCompletionPage({}: DefaultPageProps) {
  let { formDraftId } = useParams();
  const navigate = useNavigate();

  const [
    { data: formDraft, loading: formDraftLoading, response },
    refetchDraft,
  ] = apiHooks.drafts.get(formDraftId!);

  useEffect(() => {
    if (response && response.status === 404) {
      navigate(routeFns.notFound(), { replace: true });
    }
  }, [formDraft, formDraftLoading]);

  return formDraftLoading ? (
    <></>
  ) : !formDraft ? (
    // TODO: generic 404 component
    <div>Oops - couldn't find that draft!</div>
  ) : (
    <WithPatient
      patientId={formDraft.patientId}
      children={(patient) => (
        <FormDraftPageInner
          formDraft={formDraft}
          patient={patient}
          refetchDraft={refetchDraft}
        />
      )}
    />
  );
}

export function OpenPatientEditDialog() {
  const { formDraftId, patientId } = useParams();
  const navigate = useNavigate();

  const userContext = React.useContext(LoggedInUserContext);
  const user = userContext?.user!;

  const isCurrentUserGuestUser = isGuestUser(user);

  return (
    <>
      {!isCurrentUserGuestUser && (
        <Button
          sx={{ mt: 1 }}
          customFontSize={2}
          label="Edit patient information"
          color="primary"
          variant="outlined"
          startIcon={ButtonIcon.create}
          onClick={() =>
            navigate(routeFns.patientEditDialog(formDraftId!, patientId!))
          }
        />
      )}
    </>
  );
}
