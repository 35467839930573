import {
  ExtendedThalamosUser,
  FormContextData,
  GetPatientMhaStateResponse,
  MhaStatus,
  PatientTimelineResponse,
} from "@aspire/common";
import dayjs from "dayjs";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";
import {
  Banner,
  BannerList,
  LoadingSpinner,
} from "~/components/design-system/index.js";
import { PatientBanner } from "~/components/layout/index.js";
import { useMhaStatus, usePatientTimeline } from "~/hooks/apiCalls.js";
import { LoggedInUserContext } from "../../Contexts.js";
import { EditMhaStatus } from "./EditMhaStatus.js";

export function userCanEditMhaStatus(
  user: ExtendedThalamosUser | undefined,
): boolean {
  const organisationPatientStateEnabled =
    user?.sessionOrganisationConfiguration?.patientStateEnabled ?? false;

  const isMhaMember =
    user?.sessionContext?.teamType === "mha" &&
    user?.sessionContext?.role === "member";

  return organisationPatientStateEnabled && isMhaMember;
}

export function EditMhaStatusPage() {
  const { patientId } = useParams();

  const { patientTimeline, reloadPatientTimeline } = usePatientTimeline({
    patientId: patientId,
  });
  const { mhaStatus } = useMhaStatus({ patientId: patientId });

  const userContext = useContext(LoggedInUserContext);
  const showEditMhaStatus = userCanEditMhaStatus(userContext?.user);

  return !showEditMhaStatus ? (
    <Banner
      bannerType={BannerList.ERROR}
      title={"Incorrect permissions to edit MHA status page"}
    />
  ) : patientTimeline && mhaStatus ? (
    <>
      <RenderPatientBannerAndEditStatus
        patientTimeline={patientTimeline}
        mhaStatus={mhaStatus}
        patientId={patientTimeline.patient.id}
        isScrutiny={false}
        reloadPatientTimeline={reloadPatientTimeline}
      />
    </>
  ) : (
    <LoadingSpinner />
  );
}

export function RenderPatientBannerAndEditStatus({
  patientTimeline,
  mhaStatus,
  patientId,
  hasFormWithApplicationOrMedRec,
  formContext,
  isScrutiny,
  reloadPatientTimeline,
  reloadFormContext,
}: {
  patientTimeline: PatientTimelineResponse;
  mhaStatus: GetPatientMhaStateResponse;
  patientId: string;
  isScrutiny: boolean;
  hasFormWithApplicationOrMedRec?: boolean;
  formContext?: FormContextData;
  reloadPatientTimeline?: () => void;
  reloadFormContext?: () => void;
}) {
  const nhsNumber = patientTimeline.patient.nhsNumber;

  return (
    <>
      <PatientBanner
        nhsNumber={nhsNumber ?? undefined}
        patient={patientTimeline.patient}
        patientTimeline={patientTimeline}
        isHidden={true}
        reloadPatientTimeline={reloadPatientTimeline}
      />
      <EditMhaStatus
        state={
          mhaStatus?.states[0] ?? {
            id: v4(),
            isLatest: true,
            effectiveDateTime: dayjs().toISOString(),
            patientId: patientId,
            state: { mhaStatus: { status: MhaStatus.Unknown } },
          }
        }
        reloadFormContext={reloadFormContext}
        isScrutiny={isScrutiny}
        hasFormWithApplicationOrMedRec={hasFormWithApplicationOrMedRec}
        formContext={formContext}
      />
    </>
  );
}
