import { Info } from "@mui/icons-material";
import { Box, List, ListItem, Typography, useTheme } from "@mui/material";
import {
  Button,
  PopupDialog,
  PopupDialogTitle,
} from "~/components/design-system/index.js";

export function PdsDemographicsExplainer(props: { onClose: () => void }) {
  const theme = useTheme();
  const rootBackgroundColor = theme.palette.secondary.light;
  const sideBorderColor = theme.palette.primary.main;
  return (
    <PopupDialog
      title="PDS Search Criteria"
      open={true}
      onClose={props.onClose}
      sx={{ maxWidth: "50rem" }}
    >
      <PopupDialogTitle
        titleText="PDS Search Criteria"
        closeDialog={props.onClose}
      />
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            flexDirection: "column",
            borderLeft: `6px solid ${sideBorderColor}`,
            borderRadius: "6px",
            backgroundColor: rootBackgroundColor,
            padding: theme.spacing(2),
            rowGap: theme.spacing(2),
          }}
          data-testid="root-id"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
                gap: theme.spacing(2),
              }}
            >
              <Info
                sx={{
                  color: theme.palette.secondary.darker,
                  fontSize: "48px",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  flex: 1,
                  color: theme.palette.text.secondary,
                  gap: theme.spacing(0.5),
                }}
              >
                <Typography>
                  Searching PDS Demographics will only return a single unique
                  match. The following combination of search criteria is
                  recommended to successfully find a unique match, these are
                  enabled at all times and cannot be changed.
                </Typography>
                <List
                  sx={{
                    listStyleType: "disc",
                    paddingLeft: "1rem",
                    paddingTop: 0,
                  }}
                >
                  <ListItem sx={{ display: "list-item" }} disableGutters>
                    <Typography component="span" sx={{ fontWeight: "bold" }}>
                      Wildcard search:
                    </Typography>
                    <List
                      sx={{
                        listStyleType: '"-"',
                        paddingLeft: "1rem",
                        paddingBottom: 0,
                      }}
                    >
                      <ListItem sx={{ display: "list-item", paddingTop: 0 }}>
                        First Name: First three characters and a wildcard are
                        used to cater for different name spellings and
                        abbreviations (for example, Jon finds Jonathan). User
                        cannot enter their own wildcards.
                      </ListItem>
                      <ListItem sx={{ display: "list-item", paddingBottom: 0 }}>
                        Postcode: First two characters and a wildcard is used to
                        cater for people who have moved locally but not updated
                        on PDS (for example, LS will match LS11 6AD). User
                        cannot enter their own wildcards.
                      </ListItem>
                    </List>
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }} disableGutters>
                    <Box>
                      <Typography component="span" sx={{ fontWeight: "bold" }}>
                        Non-fuzzy search:
                      </Typography>{" "}
                      Techniques to find patients using a looser search criteria
                      (for example by checking common homophones such as Smith
                      vs Smythe) is disabled to reduce the chance of multiple
                      matches
                    </Box>
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }} disableGutters>
                    <Typography component="span" sx={{ fontWeight: "bold" }}>
                      Historic data:
                    </Typography>{" "}
                    Historic search is included to match patients when only a
                    previous name or address is known. When a search done on
                    historic data result in a match, the patient's current data
                    will be returned, not the historic data used to identify the
                    match
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Button variant="outlined" label="Close" onClick={props.onClose} />
      </Box>
    </PopupDialog>
  );
}
