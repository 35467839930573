import { SuccessPatientIndexSearchResultV2 } from "@aspire/common";
import { Box, Stack } from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FormTitle } from "~/components/design-system/index.js";
import { LoadingSpinner } from "~/components/design-system/LoadingSpinner.js";
import { PatientSearchResultBanner } from "../PatientIndex/SearchResultBanners.js";
import { SearchResult } from "./SearchResult.js";

export type SearchResultsProps = {
  searchResult?:
    | SuccessPatientIndexSearchResultV2
    | "loading"
    | "too-many-matches"
    | "too-many-matches-pds";
  onSelectedIndexChanged?: (index: number | null) => void;
  canSearchByPdsDemographics: boolean;
  criteriaType: "nhs-number" | "demographics" | "pds-demographics";
  setCriteriaType: (
    criteriaType: "nhs-number" | "demographics" | "pds-demographics",
  ) => void;
};

export const SearchResults = ({
  searchResult,
  onSelectedIndexChanged,
  canSearchByPdsDemographics,
  criteriaType,
  setCriteriaType: setCriteriaType,
}: SearchResultsProps) => {
  // This is where we store the index of the selected search result.
  // null means no result is selected.
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);

  const { t } = useTranslation();

  // This is called whenever a search result is selected or deselected.
  const onResultSelected = useCallback(
    (index: number | null) => {
      // If the user has picked the index which was already selected, deselect it
      const targetIndex = selectedIndex === index ? null : index;

      setSelectedIndex(targetIndex);
      onSelectedIndexChanged?.(targetIndex);
    },
    [onSelectedIndexChanged, selectedIndex],
  );

  if (searchResult === undefined) {
    return null;
  }

  // If data is still pending, show a loading spinner
  if (searchResult === "loading") {
    return <LoadingSpinner />;
  }

  if (searchResult === "too-many-matches") {
    return (
      <Stack gap="1rem">
        <PatientSearchResultBanner
          canSearchByPdsDemographics={canSearchByPdsDemographics}
          criteriaType={criteriaType}
          setCriteriaType={setCriteriaType}
          outcome={"error/too-many-matches"}
        />
      </Stack>
    );
  }

  if (searchResult === "too-many-matches-pds") {
    return (
      <Stack gap="1rem">
        <PatientSearchResultBanner
          canSearchByPdsDemographics={canSearchByPdsDemographics}
          criteriaType={criteriaType}
          setCriteriaType={setCriteriaType}
          outcome={"error/too-many-matches-pds"}
        />
      </Stack>
    );
  }

  // Turn the search results into data required by the frontend components
  const searchResults = (searchResult.matchedPatients ?? []).map((patient) => ({
    type: "aspire-patient" as const,
    patient,
  }));

  return (
    <Stack>
      <Box sx={{ mb: "1rem" }}>
        <PatientSearchResultBanner
          canSearchByPdsDemographics={canSearchByPdsDemographics}
          criteriaType={criteriaType}
          setCriteriaType={setCriteriaType}
          outcome={
            searchResults.length === 0
              ? "error/no-matches"
              : searchResults.length > 1
                ? "info/multiple-matches"
                : "success/unique-match"
          }
        />
      </Box>
      {searchResults.length > 0 && (
        <Box sx={{ mb: "1rem" }}>
          <FormTitle
            useReducedTopPadding
            hasTitleBottomMargin={false}
            hasContainerMarginBottom={false}
            titleText={t("components.cardExtended.title")}
          />
        </Box>
      )}
      {searchResults.map((sr, i) => (
        <SearchResult
          key={i}
          searchResult={sr}
          selected={selectedIndex === i}
          setSelected={() => onResultSelected(i)}
        />
      ))}
    </Stack>
  );
};
