import { Container, Input, Stack } from "@mui/material";
import { ValidateShareCodeV1Response } from "@thalamos/common";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { api } from "~/api.js";
import {
  Banner,
  BannerList,
  Button,
  renderErrorToast,
  ToastNotifications,
} from "~/components/design-system/index.js";
import { routeFns } from "~/routes.js";

export function EMHFormsShareCodePage({}: {}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const shareCodeFromParams = searchParams.get("shareCode");

  const [shareCode, setShareCode] = React.useState(shareCodeFromParams);
  const [submitting, setSubmitting] = React.useState(false);

  const [validatedShareCodeResponse, setValidatedShareCodeResponse] =
    React.useState<
      | Omit<ValidateShareCodeV1Response, "incidentId">
      | ValidateShareCodeV1Response
      | null
    >(null);

  React.useEffect(() => {
    if (
      validatedShareCodeResponse &&
      "incidentId" in validatedShareCodeResponse
    ) {
      navigate(
        routeFns.contextSelector(
          routeFns.visionPreviewIncident(validatedShareCodeResponse.incidentId),
        ),
      );
    }
  }, [validatedShareCodeResponse]);

  const [email, setEmail] = React.useState<string | null>(null);
  const [name, setName] = React.useState<string | null>(null);
  const [error, setError] = React.useState<string | null>(null);

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Stack maxWidth={400} minWidth={400} gap={"2rem"}>
        <Input
          value={shareCode}
          onChange={(e) => setShareCode(e.target.value)}
        />
        <Button
          disabled={submitting || !shareCode || !!validatedShareCodeResponse}
          onClick={async () => {
            setSubmitting(true);
            setError(null);
            try {
              const response = await api.vision.validateShareCode(shareCode!);

              if (response.status === 200) {
                setValidatedShareCodeResponse(response.data);
              } else {
                setError((response.data as any).reason);
              }
            } catch (e) {
              setError((e as any).message);
            } finally {
              setSubmitting(false);
            }
          }}
          label="Submit share code"
          testId="code-submit-button"
        />
        {error && <Banner bannerType={BannerList.ERROR} title={error} />}

        {validatedShareCodeResponse &&
          !("incidentId" in validatedShareCodeResponse) && (
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                setSubmitting(true);
                try {
                  const response = await api.vision.accessRequest({
                    email: email!,
                    name: name!,
                    shareCode: shareCode!,
                  });

                  if (response.status === 200) {
                    window.location.href = response.data.redirectUrl;
                  } else {
                    renderErrorToast({
                      message: (response.data as any).reason,
                    });
                  }
                } catch (e) {
                  renderErrorToast({ message: (e as any).message });
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              <Stack sx={{ mt: "2rem" }} gap="1rem">
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
                <Input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                />
                <Button
                  type="submit"
                  label="Go to Login & View Form"
                  disabled={!email || !name || submitting}
                  testId="login-button"
                />
              </Stack>
            </form>
          )}
      </Stack>
      <ToastNotifications />
    </Container>
  );
}
