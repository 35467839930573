import {
  ChoiceDifferencesEntry,
  ExternalMappedDemographics,
  Patient,
} from "@aspire/common";
import { ChoiceGroupProps } from "./ChoiceLayout.js";

export type SystemType = "eMHA" | "Other";

export type Choice = {
  system: SystemType;
  type: string;
  value: string | null;
};

export type PatientRecords = {
  eMHA: Patient | undefined;
  Other: ExternalMappedDemographics | undefined;
};

/**
 * Filters out undefined/null values from a list of choices,
 * allowing special cases for "dob" and "deathNotificationStatus".
 *
 * @param {Choice[]} choices - The array of choices to filter.
 * @returns {Choice[]} - The filtered array of choices.
 */
const getDefinedChoices = (choices: Choice[]): Choice[] => {
  return choices.filter(
    (choice) =>
      (choice.value ?? undefined !== undefined) ||
      ["dob", "deathNotificationStatus"].includes(choice.type),
  );
};

/**
 * Identifies differences in choice values within groups of choices.
 *
 * This function iterates over an array of choice groups and checks if there are
 * any mismatches between values of choices within the same group. It returns an
 * array of objects containing the `type` and `value` of the differing choices.
 *
 * Filter out undefined/null values**: Ensures only defined choices are considered, allowing special cases for "dob" and "deathNotificationStatus".
 * Compare choices within the same group
 * Filter out null results, leaving only external differences
 *
 * @param {ChoiceGroupProps[]} data - The array of choice groups to compare.
 * @returns {Array<{ type: string; value: string }>} - List of detected differences.
 */
export const findChoiceDifferences = (
  data: ChoiceGroupProps[],
): ChoiceDifferencesEntry[] => {
  return data
    .map((item) => {
      let difference: ChoiceDifferencesEntry | null = null;

      const definedChoices = getDefinedChoices(item.choices);

      for (const choice of definedChoices) {
        const otherChoice = definedChoices.find(
          (other) => other.system !== choice.system,
        );

        if (otherChoice && choice.value !== otherChoice.value) {
          difference = {
            type: otherChoice.type,
            value: otherChoice.value ?? "",
          };
          break;
        }
      }

      return difference;
    })
    .filter((item): item is Exclude<typeof item, null> => item !== null);
};

export const doAllValuesMatch = (choices: Choice[]) => {
  const definedChoices = getDefinedChoices(choices);

  const allChoicesMatch = definedChoices.every(
    (choice) => choice.value === definedChoices[0].value,
  );

  return allChoicesMatch;
};

/**
 * Find the obvious choice.
 * Perhaps there is only one.
 * Or perhaps they are all the same.
 */
export const obviousChoice = (choices: Choice[]) => {
  const nonNullChoices = choices.filter(
    (choice) =>
      choice.value !== null ||
      ["dob", "deathNotificationStatus"].includes(choice.type),
  );

  return doAllValuesMatch(choices)
    ? nonNullChoices.length > 0
      ? nonNullChoices[0].system
      : choices[0].system
    : undefined;
};
