import { ExtendedPatient } from "@aspire/common";
import { Box, CircularProgress, Typography } from "@mui/material";
import * as React from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router";
import { v4 } from "uuid";
import { api } from "~/api.js";
import {
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system/index.js";
import { routeFns } from "~/routes.js";
import { logErrorToDatadog } from "~/tracing.js";

function hasPdsLink(patient: ExtendedPatient) {
  return patient.externalLinks.some(
    (link) => link.externalSystemType === "pds",
  );
}

async function processPatient(patientId: string, navigate: NavigateFunction) {
  const patientResponse = await api.patients.get(patientId);

  if (
    !patientResponse ||
    patientResponse.status !== 200 ||
    !patientResponse.data
  ) {
    throw new Error("Failed to get patient");
  }

  const pdsLink = patientResponse.data.externalLinks.find(
    (l) => l.externalSystemType === "pds",
  );

  if (pdsLink) {
    navigate(routeFns.patientSyncExternalLink(patientId, pdsLink!.id));

    throw new Error("Patient already linked to PDS");
  }

  const nhsNumber = patientResponse.data?.nhsNumber;

  if (!nhsNumber) {
    throw new Error("Patient does not have an NHS number");
  }

  const searchPatientResponse = await api.patients.searchV2(v4(), {
    type: "nhs-number",
    nhsNumber,
    isMergePatientSearch: false,
  });

  if (!searchPatientResponse || searchPatientResponse.status !== 200) {
    throw new Error("Failed to search for patient");
  }

  if (searchPatientResponse.data.searchOutcome !== "success") {
    throw new Error("Failed to search for patient");
  }

  if (searchPatientResponse.data.pdsResult !== "success") {
    throw new Error("Failed to search for patient on PDS");
  }

  const reretrievedPatientResponse = await api.patients.get(patientId);

  if (
    !reretrievedPatientResponse ||
    reretrievedPatientResponse.status !== 200 ||
    !reretrievedPatientResponse.data
  ) {
    throw new Error("Failed to re-retrieve patient");
  }
  const newPdsLink = reretrievedPatientResponse.data.externalLinks.find(
    (l) => l.externalSystemType === "pds",
  );

  if (!newPdsLink) {
    throw new Error("Patient was not linked to PDS on re-retrieval");
  }

  navigate(routeFns.patientSyncExternalLink(patientId, newPdsLink!.id));
}

export function PatientLinkToPdsPage(props: {}) {
  const [processing, setProcessing] = React.useState(true);
  const { patientId } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (patientId) {
      try {
        processPatient(patientId, navigate)
          .then(() => {
            renderSuccessToast({ message: "Patient linked to PDS" });
          })
          .catch((error: unknown) => {
            if (error instanceof Error) {
              logErrorToDatadog(error);
            }

            renderErrorToast({
              message:
                "Failed to link patient to PDS: " + (error as any).message,
            });
            setProcessing(false);
          });
      } catch (error) {
        renderErrorToast({ message: "Failed to link patient to PDS" });
        setProcessing(false);
      }
    } else {
      renderErrorToast({ message: "No patient ID provided" });
      setProcessing(false);
    }
  }, [patientId]);

  React.useEffect(() => {
    if (!processing) {
      navigate(patientId ? routeFns.patientHome(patientId) : routeFns.home());
    }
  });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <CircularProgress />
      <Typography sx={{ mt: "1rem" }} variant="h6">
        Attempting to link patient to PDS...
      </Typography>
    </Box>
  );
}
