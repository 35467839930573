export const addOrgMemberValues = [
  { value: "member", label: "Add Member" },
  { value: "manager", label: "Add Admin" },
  { value: "demographics-manager", label: "Add Demographics Admin" },
];

export const addTeamMemberValues = [
  { value: "member", label: "Add Member" },
  { value: "manager", label: "Add Admin" },
];
