import {
  ExternalPatientLinkEnhanced as BaseExternalPatientLink,
  CreateExternalPatientDemograhicsPullEventRequest,
  getActiveMhaStatus,
  isGuestUserSession,
  MhaStatus,
  Patient,
  PatientStateWithContext,
  PatientTimelineResponse,
} from "@aspire/common";
import { GppMaybeOutlined, Info } from "@mui/icons-material";
import { Box, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { styled, Theme } from "@mui/material/styles";
import { NhsNumberSchema } from "@thalamos/common";
import dayjs from "dayjs";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { v4 } from "uuid";
import { api } from "~/api.js";
import {
  CreateLinkButton,
  LinkToPdsButton,
} from "~/components/design-system/ExternalPatientLink/CreateLinkButton/CreateLinkButton.js";
import {
  Banner,
  BannerList,
  Button,
  PopupDialog,
  PopupDialogTitle,
} from "~/components/design-system/index.js";
import { isValidPatientDemographics } from "~/components/util.js";
import { useMhaStatus } from "~/hooks/apiCalls.js";
import { userCanEditMhaStatus } from "~/pages/EditMhaStatus/EditMhaStatusPage.js";
import { LoggedInUserContext } from "../../../Contexts.js";
import { useScreenDetection } from "../../../hooks/ScreenDetection/useScreenDetection.js";
import { useStickyHeader } from "../../../hooks/StickyHeader/useStickyHeader.js";
import { PatientMergeDialog } from "../../../pages/PatientIndex/PatientMerge/PatientMergeDialog.js";
import { PatientUndoMergeDialog } from "../../../pages/PatientIndex/PatientMerge/PatientUndoMergeDialog.js";
import { routeFns } from "../../../routes.js";
import { ManualPullButton } from "../../design-system/ExternalPatientLink/ManualPullButton/ManualPullButton.js";
import { MenuFlyout } from "../../design-system/MenuFlyout.js";
import { HorizontalLine } from "../../form/HorizontalLine.js";
import { VerticalLine } from "../../form/VerticalLine.js";
import { PatientCrisisInfo } from "../PatientCrisisInfo.js";
import { StickyContainer } from "../styleWrappers.js";
import "./PatientBannerTransition.css";
import {
  infoBoxMhaStatusTitle,
  InfoBox as MhaStatusInfoBox,
  PatientStateDisplay,
} from "./PatientStateDisplay.js";

type ExternalPatientLink = Pick<
  BaseExternalPatientLink,
  | "id"
  | "patientId"
  | "externalSystemType"
  | "externalSystemId"
  | "externalSystemDisplayName"
  | "externalPatientIdType"
  | "externalPatientIdValue"
  | "dateLastProcessed"
  | "priorityWithinExternalSystem"
  | "canPull"
  | "canPush"
>;

export interface PatientBannerProps {
  patient: Patient;

  patientTimeline?: PatientTimelineResponse;
  reloadPatientTimeline?: () => void;
  nhsNumber?: string;

  externalPatientLinks?: {
    links: ExternalPatientLink[];
    onSync: (
      externalPatientLink: BaseExternalPatientLink,
      reason: CreateExternalPatientDemograhicsPullEventRequest["reason"],
    ) => Promise<void>;
  };

  disableSticky?: boolean;
  isHorizontalLineHidden?: boolean;
  isHidden?: boolean;
  showUnknownMhaStatus?: boolean;
}

export interface PatientBannerInnerProps extends PatientBannerProps {
  showNhsLabel: boolean;
  isEditable: boolean;
  patientMhaStatusEnabled: boolean;
  showEditMhaStatus: boolean;
  hasPatientMergeAccess: boolean;
  isGuestUserSession: boolean;
  mhaStatus:
    | {
        states: PatientStateWithContext[];
      }
    | undefined;
}

export interface PatientBannerCompactedProps extends PatientBannerProps {
  showNhsLabel: boolean;
  patientMhaStatusEnabled: boolean;
  mhaStatus:
    | {
        states: PatientStateWithContext[];
      }
    | undefined;
}

type MainTextStylesObject = {
  color: string;
  fontSize: string;
};

type MainTextStylesFunction = (theme: Theme) => MainTextStylesObject;

type MainTextStyles = MainTextStylesObject | MainTextStylesFunction;

type BannerContainerProps = {
  issticky?: string;
  ismobilemode?: string;
  theme?: Theme;
  restricted?: boolean;
  deceased?: boolean;
};

const BannerContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "isSticky",
})<BannerContainerProps>(
  ({ issticky, ismobilemode, restricted, deceased, theme }) => ({
    flexDirection: "column",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: theme.spacing(0.8),
    ...(restricted && { border: "1px solid red" }),
    ...(deceased && { border: "1px solid black" }),
    padding: issticky && ismobilemode ? theme.spacing(1.5) : theme.spacing(2.5),
  }),
);

const getSmallTextStyles = (theme: Theme) => ({
  color: "primary.hint",
  fontSize: theme.spacing(1.375),
});

const compactTextStyles = (theme: Theme) => ({
  color: "text.primary",
  fontSize: theme.spacing(1.625),
});

const shouldShowNhsLabel = (
  nhsNumberEnabled: boolean,
  nhsNumber: string | undefined,
) => {
  return !!(nhsNumberEnabled || (!nhsNumberEnabled && nhsNumber));
};

function Spacer() {
  const theme = useTheme();
  return (
    <Box sx={{ marginLeft: "0.7em", marginRight: "0.7em", height: "100%" }}>
      <VerticalLine color={theme.palette.text.disabled} height="100%" />
    </Box>
  );
}

function SuggestedMergePopup({
  alertId,
  patientId,
}: {
  alertId: string;
  patientId: string;
}) {
  const [open, setOpen] = React.useState(true);
  const { t } = useTranslation();

  const onClose = useCallback(() => {
    api.patients.markAlertAsSeen(patientId, alertId);
    setOpen(false);
  }, [alertId, patientId]);

  return (
    <PopupDialog open={open} onClose={onClose}>
      <PopupDialogTitle
        titleText={`Patient local record is wrongly identified`}
        closeDialog={onClose}
      />
      <Typography>
        The NHS number on this record has been replaced in NHS national systems.
        The patient should be told of the replacement NHS number. The new NHS
        number is already linked to an existing patient record within eMHA -
        your local back office have been notified and will merge the records
        together.
      </Typography>
      <Box display="flex" sx={{ justifyContent: "center", mt: "1em" }}>
        <Button variant="outlined" label={"Close"} onClick={onClose} />
      </Box>
    </PopupDialog>
  );
}

function InvalidatedPatientPopup({
  alertId,
  patientId,
}: {
  alertId: string;
  patientId: string;
}) {
  const [open, setOpen] = React.useState(true);
  const { t } = useTranslation();

  const onClose = useCallback(() => {
    api.patients.markAlertAsSeen(patientId, alertId);
    setOpen(false);
  }, [alertId, patientId]);

  return (
    <PopupDialog open={open} onClose={onClose}>
      <PopupDialogTitle
        titleText={`NHS number is no longer valid`}
        closeDialog={onClose}
      />
      <Typography>
        This local record is wrongly identified and the NHS number that was on
        this record is no longer valid. The record has been decoupled from PDS
        and has been referred to the local back office for processing.
      </Typography>
      <Banner
        sx={{ marginTop: "1em" }}
        bannerType={BannerList.WARNING}
        title="All demographic and clinical
        information for this patient should be treated with caution until the
        processing is complete as there are data anomalies on the record which
        could constitute a clinical risk. The anomalies might be related to the
        clinical as well as the demographic record."
      />
      <Box display="flex" sx={{ justifyContent: "center", mt: "1em" }}>
        <Button variant="outlined" label={"Close"} onClick={onClose} />
      </Box>
    </PopupDialog>
  );
}

function DeceasedPatientPopup({
  alertId,
  patientId,
}: {
  alertId: string;
  patientId: string;
}) {
  const [open, setOpen] = React.useState(true);
  const { t } = useTranslation();

  const onClose = useCallback(() => {
    api.patients.markAlertAsSeen(patientId, alertId);
    setOpen(false);
  }, [alertId, patientId]);

  return (
    <PopupDialog open={open} onClose={onClose}>
      <PopupDialogTitle
        titleText={`Patient is marked as deceased`}
        closeDialog={onClose}
      />
      <Typography>
        A death notification status has been added to this patient record.
        Access to this patient's record will be audited and should be limited.
      </Typography>

      <Box display="flex" sx={{ justifyContent: "center", mt: "1em" }}>
        <Button variant="outlined" label={"Close"} onClick={onClose} />
      </Box>
    </PopupDialog>
  );
}

export function PatientBanner(props: PatientBannerProps) {
  const userContext = React.useContext(LoggedInUserContext);
  const user = userContext?.user;
  const nhsNumberEnabled =
    user?.sessionOrganisationConfiguration?.nhsNumberEnabled ?? false;

  const isEditable = !isGuestUserSession(user);

  const patientMhaStatusEnabled =
    user?.sessionOrganisationConfiguration?.patientStateEnabled ?? false;

  const showEditMhaStatus = userCanEditMhaStatus(user);

  const hasPatientMergeAccess = user?.hasPatientMergeAccess ?? false;

  const { mhaStatus } = useMhaStatus({
    patientId: props.patient.id,
  });

  // The NHS label is displayed if the NHS number feature is turned on.
  // If the feature is turned off, the label will still be displayed if the patient already has an NHS number recorded.
  const showNhsLabel = shouldShowNhsLabel(nhsNumberEnabled, props.nhsNumber);

  const guestUserSession = isGuestUserSession(user);

  return (
    <PatientBannerInner
      {...props}
      showNhsLabel={showNhsLabel}
      isEditable={isEditable}
      patientMhaStatusEnabled={patientMhaStatusEnabled}
      showEditMhaStatus={showEditMhaStatus}
      hasPatientMergeAccess={hasPatientMergeAccess}
      isGuestUserSession={guestUserSession}
      mhaStatus={mhaStatus}
    />
  );
}

export function PatientBannerInner(props: PatientBannerInnerProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const userContext = React.useContext(LoggedInUserContext);
  const { isSticky } = useStickyHeader();
  const { isMobileView } = useScreenDetection();
  const [isMainMenuOpen, setIsMainMenuOpen] = React.useState(false);
  const location = useLocation();
  const [showPatientMergeDialog, setShowPatientMergeDialog] =
    React.useState(false);

  const [showPatientUndoMergeDialog, setShowPatientUndoMergeDialog] =
    React.useState(false);

  // Redirect to edit page if patient demographics are incomplete so
  // they can be fixed up
  useEffect(() => {
    if (!isValidPatientDemographics(props.patient)) {
      navigate(routeFns.patientEdit(props.patient.id, location.pathname));
    }
  }, [props.patient, navigate]);

  const mainTextStyles = {
    color: "text.primary",
    fontSize: isMobileView ? theme.spacing(1.5) : theme.spacing(2),
  };

  const patientAge = dayjs().diff(dayjs(props.patient.dateOfBirth), "year");

  const isNotSticky = props.disableSticky || !isSticky;

  const showExtendedContent =
    props.disableSticky || (!isSticky && !isMobileView);

  const cardRef = React.useRef(null);

  const menuFlyoutOptions = [];

  const disableEditMHAStatusBtn =
    location.pathname === routeFns.editMhaStatusPage(props.patient.id) ||
    location.pathname.endsWith("confirm-mha-status-finalise");

  // If we are already on the patient merge popup, we should disable the merge and unmerge buttons
  const disablePatientMergeAndUnmergeBtn =
    location.pathname === routeFns.patientMergeSearch(props.patient.id);

  if (location.pathname !== routeFns.patientHome(props.patient.id)) {
    menuFlyoutOptions.push({
      icon: "view",
      name: t("layout.patientBanner.link"),
      link: routeFns.patientHome(props.patient.id),
      disabled: false,
    });
  }

  if (props.isEditable) {
    menuFlyoutOptions.push({
      icon: "person",
      name: t("layout.patientBanner.edit"),
      link: routeFns.patientEdit(props.patient.id),
      disabled: false,
    });
  }

  if (props.hasPatientMergeAccess && !disablePatientMergeAndUnmergeBtn) {
    menuFlyoutOptions.push({
      icon: "merge",
      name: t("layout.patientBanner.merge"),
      onClick: () => {
        if (
          userContext?.user?.sessionOrganisationConfiguration?.mergeV2Enabled
        ) {
          navigate(routeFns.patientMergeSearch(props.patient.id));
        } else {
          setShowPatientMergeDialog(true);
        }
      },
      disabled: false,
    });
  }

  const mostRecentlyMergedPatient =
    props.patientTimeline?.mergedPatientData.lastMergedPatient;

  // we should only show the undo merge option if the merged patient
  // was last merged more than 30 days ago.
  const isMostRecentlyMergedBefore30Days = dayjs(
    mostRecentlyMergedPatient?.lastMerged,
  ).isBefore(dayjs().add(30, "day"));

  if (
    mostRecentlyMergedPatient &&
    isMostRecentlyMergedBefore30Days &&
    props.hasPatientMergeAccess &&
    !disablePatientMergeAndUnmergeBtn
  ) {
    menuFlyoutOptions.push({
      icon: "undo",
      name: t("layout.patientBanner.undoMerge"),
      onClick: () => setShowPatientUndoMergeDialog(true),
      disabled: false,
    });
  }

  if (isMobileView && !disableEditMHAStatusBtn && props.showEditMhaStatus) {
    menuFlyoutOptions.push({
      icon: "edit",
      name: t("pages.editMHAStatus.editMHAStatus"),
      link: routeFns.editMhaStatusPage(props.patient.id),
      disabled: false,
    });
  }

  const superesedMergeAlertToShow =
    props.patientTimeline?.demographicsAlertsToShow.find(
      (alert) =>
        alert.type === "suggested-merge" &&
        alert.suggestedMerge.type === "superseded",
    );

  const invalidatedPatientAlertToShow =
    props.patientTimeline?.demographicsAlertsToShow.find(
      (alert) => alert.type === "alert" && alert.alert.type === "invalidated",
    );

  const deceasedPatientAlertToShow =
    props.patientTimeline?.demographicsAlertsToShow.find(
      (alert) => alert.type === "alert" && alert.alert.type === "deceased",
    );

  const externalLinks = props.externalPatientLinks?.links ?? [];

  const isRioEnabled =
    (
      userContext?.user?.sessionOrganisationConfiguration
        ?.rioInstancesEnabled ?? []
    ).length > 0;

  const isPdsEnabled =
    userContext?.user?.sessionOrganisationConfiguration?.pdsEnabled &&
    userContext?.user?.sessionOrganisationConfiguration
      ?.pdsEndUserOrganisationODSCode;

  return (
    <Box data-testid="patient-banner">
      {superesedMergeAlertToShow && (
        <SuggestedMergePopup
          alertId={superesedMergeAlertToShow.id}
          patientId={props.patient.id}
        />
      )}

      {invalidatedPatientAlertToShow && (
        <InvalidatedPatientPopup
          alertId={invalidatedPatientAlertToShow.id}
          patientId={props.patient.id}
        />
      )}

      {deceasedPatientAlertToShow && (
        <DeceasedPatientPopup
          alertId={deceasedPatientAlertToShow.id}
          patientId={props.patient.id}
        />
      )}
      <RenderMergeDialogs
        showPatientMergeDialog={showPatientMergeDialog}
        showPatientUndoMergeDialog={showPatientUndoMergeDialog}
        setShowPatientMergeDialog={setShowPatientMergeDialog}
        setShowPatientUndoMergeDialog={setShowPatientUndoMergeDialog}
        reloadPatientTimeline={props.reloadPatientTimeline}
      />

      <StickyContainer
        disablesticky={props.disableSticky ? "true" : undefined}
        issticky={isSticky || isMobileView ? "true" : undefined}
        ismobilemode={isMobileView ? "true" : undefined}
        top={isMobileView ? 58 : props.isGuestUserSession ? 30 : 0}
        style={{ zIndex: 999 }}
      >
        <BannerContainer
          issticky={isSticky ? "true" : undefined}
          ismobilemode={isMobileView ? "true" : undefined}
          restricted={props.patient.restricted ? true : false}
          deceased={
            ["formal", "informal"].includes(
              props.patient.deathNotificationStatus ?? "",
            )
              ? true
              : false
          }
        >
          <Box
            sx={{
              "&:hover": {
                cursor: "pointer",
                "& .MuiIconButton-root": {
                  backgroundColor: theme.palette.secondary.lightBlue,
                },
              },
            }}
            display="flex"
            onClick={() => setIsMainMenuOpen(true)}
          >
            <Box>
              <RenderPatientName
                deceased={["formal", "informal"].includes(
                  props.patient.deathNotificationStatus ?? "",
                )}
                restricted={props.patient.restricted}
                familyName={props.patient.name.family}
                givenName={props.patient.name.given}
                styleType={mainTextStyles}
                fontSize={18}
              />
            </Box>

            <Box display="flex" marginLeft="auto">
              {props.showNhsLabel && (
                <RenderNhsNumber nhsNumber={props.nhsNumber} />
              )}
              {!props.isHidden && (
                <Box
                  ref={cardRef}
                  sx={{
                    display: "flex",
                    marginLeft: (theme) => theme.spacing(5.2),
                    alignItems: "center",
                  }}
                  onClick={() => setIsMainMenuOpen(true)}
                >
                  {!!menuFlyoutOptions.length && (
                    <MenuFlyout
                      cardRef={cardRef}
                      options={menuFlyoutOptions}
                      isOpen={isMainMenuOpen}
                      onClose={() => setIsMainMenuOpen(false)}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <CSSTransition
            in={showExtendedContent}
            timeout={300}
            classNames={{
              enter: "fade-enter",
              enterActive: "fade-enter-active",
              exit: "fade-exit",
              exitActive: "fade-exit-active",
            }}
            unmountOnExit
          >
            <Box sx={{ marginTop: "0.5em", width: "100%" }}>
              <HorizontalLine noMargin={true} />
            </Box>
          </CSSTransition>
          <CSSTransition
            in={showExtendedContent}
            timeout={300}
            classNames={{
              enter: "fade-enter",
              enterActive: "fade-enter-active",
              exit: "fade-exit",
              exitActive: "fade-exit-active",
            }}
            unmountOnExit
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginTop: "0.5em",
                }}
              >
                <RenderPatientAge
                  patientAge={
                    patientAge ? patientAge : t("layout.patientBanner.unknown")
                  }
                  styleType={mainTextStyles}
                />

                <Spacer />

                <RenderPatientAddress
                  address={props.patient.address.address}
                  styleType={mainTextStyles}
                />

                <Spacer />
                <RenderPostCode
                  postalCode={props.patient.address.postalCode}
                  styleType={mainTextStyles}
                />

                <Spacer />

                <RenderDateOfBirth
                  dateOfBirth={
                    props.patient.dateOfBirth
                      ? dayjs(props.patient.dateOfBirth).format("DD MMM YYYY")
                      : t("layout.patientBanner.unknown")
                  }
                  styleType={mainTextStyles}
                />

                <Spacer />
              </Box>
              {
                // We are feature switched to show NHS Numbers
                props.patientTimeline?.patient &&
                  props.showNhsLabel &&
                  // We have an NHS Number
                  props.nhsNumber &&
                  // We have a Rio instance
                  isPdsEnabled &&
                  // There are some existing external patient links
                  (props.externalPatientLinks?.links ?? []).length > 0 &&
                  // None of the external patient links are to our system
                  !(props.externalPatientLinks?.links ?? []).find(
                    (l) => l.externalSystemType === "pds",
                  ) && (
                    <Stack
                      direction={"column"}
                      gap={"0.5rem"}
                      sx={{ marginTop: "0.5rem" }}
                    >
                      <LinkToPdsButton
                        patient={props.patientTimeline?.patient}
                      />
                    </Stack>
                  )
              }
              {
                // We are feature switched to show NHS Numbers
                props.showNhsLabel &&
                  // We have an NHS Number
                  props.nhsNumber &&
                  // We have a Rio instance
                  isRioEnabled &&
                  // There are some existing external patient links
                  (props.externalPatientLinks?.links ?? []).length > 0 &&
                  // None of the external patient links are to our system
                  (props.externalPatientLinks?.links ?? []).filter(
                    (externalPatientLink) => externalPatientLink.canPull,
                  ).length === 0 && (
                    <Stack
                      direction={"column"}
                      gap={"0.5rem"}
                      sx={{ marginTop: "0.5rem" }}
                    >
                      <CreateLinkButton
                        patient={props.patientTimeline?.patient!}
                      />
                    </Stack>
                  )
              }
              {externalLinks.length > 0 && (
                <Stack
                  direction={"column"}
                  gap={"0.5rem"}
                  sx={{ marginTop: "0.5rem" }}
                >
                  {externalLinks
                    .filter(
                      (externalPatientLink) => externalPatientLink.canPull,
                    )
                    .map((externalPatientLink) => {
                      return (
                        <ManualPullButton
                          key={JSON.stringify(externalPatientLink)}
                          externalSystemType={
                            externalPatientLink.externalSystemType
                          }
                          externalSystemDisplayName={
                            externalPatientLink.externalSystemDisplayName
                          }
                          dateLastProcessed={
                            externalPatientLink.dateLastProcessed
                          }
                          allExternalSystemDisplayNames={props.externalPatientLinks!.links.map(
                            (l) => l.externalSystemDisplayName ?? "",
                          )}
                          onSync={async () => {
                            navigate(
                              routeFns.patientSyncExternalLink(
                                props.patient.id,
                                externalPatientLink.id,
                              ),
                            );
                          }}
                        />
                      );
                    })}
                </Stack>
              )}
            </Box>
          </CSSTransition>
          {props.patientMhaStatusEnabled && (
            <CSSTransition
              in={isNotSticky}
              timeout={300}
              classNames={{
                enter: "fade-enter",
                enterActive: "fade-enter-active",
                exit: "fade-exit",
                exitActive: "fade-exit-active",
              }}
              unmountOnExit
            >
              <>
                <Box sx={{ marginTop: "0.5em", width: "100%" }}>
                  <HorizontalLine noMargin={true} />
                </Box>
                <Box sx={{ marginTop: "1em" }}>
                  {isMobileView ? (
                    <RenderMhaStatusInfoBox
                      mhaStatus={props.mhaStatus}
                      showUnknownMhaStatus={props.showUnknownMhaStatus}
                    />
                  ) : (
                    <PatientStateDisplay
                      state={
                        props.mhaStatus?.states[0] ?? {
                          id: v4(),
                          isLatest: true,
                          effectiveDateTime: dayjs().toISOString(),
                          patientId: props.patient.id,
                          state: { mhaStatus: { status: MhaStatus.Unknown } },
                        }
                      }
                      disableEditMHAStatusBtn={disableEditMHAStatusBtn}
                      showEditMhaStatus={props.showEditMhaStatus}
                    />
                  )}
                </Box>
              </>
            </CSSTransition>
          )}
        </BannerContainer>

        {!props.isHidden && (
          <CSSTransition
            in={showExtendedContent}
            timeout={300}
            classNames={{
              enter: "fade-enter",
              enterActive: "fade-enter-active",
              exit: "fade-exit",
              exitActive: "fade-exit-active",
            }}
            unmountOnExit
          >
            <PatientCrisisInfo />
          </CSSTransition>
        )}
        {!props.isHorizontalLineHidden && (
          <Box sx={{ my: isMobileView ? 2 : 3 }}>{/*<HorizontalLine />*/}</Box>
        )}
      </StickyContainer>
    </Box>
  );
}

export function CompactedPatientBanner(props: PatientBannerProps) {
  const userContext = React.useContext(LoggedInUserContext);
  const user = userContext?.user;
  const nhsNumberEnabled =
    user?.sessionOrganisationConfiguration?.nhsNumberEnabled ?? false;

  const patientMhaStatusEnabled =
    user?.sessionOrganisationConfiguration?.patientStateEnabled ?? false;

  const showNhsLabel = shouldShowNhsLabel(nhsNumberEnabled, props.nhsNumber);

  const { mhaStatus } = useMhaStatus({
    patientId: props.patient.id,
  });

  return (
    <CompactedPatientBannerInner
      {...props}
      showNhsLabel={showNhsLabel}
      patientMhaStatusEnabled={patientMhaStatusEnabled}
      mhaStatus={mhaStatus}
    />
  );
}

export function CompactedPatientBannerInner(
  props: PatientBannerCompactedProps,
) {
  const theme = useTheme();

  const { t } = useTranslation();

  const mainTextStyles = {
    color: "text.primary",
    fontSize: theme.spacing(1.5),
  };

  const patientAge = dayjs().diff(dayjs(props.patient.dateOfBirth), "year");

  const [showPatientMergeDialog, setShowPatientMergeDialog] =
    React.useState(false);

  const [showPatientUndoMergeDialog, setShowPatientUndoMergeDialog] =
    React.useState(false);

  return (
    <Box data-testid="compacted-patient-banner">
      <RenderMergeDialogs
        showPatientMergeDialog={showPatientMergeDialog}
        showPatientUndoMergeDialog={showPatientUndoMergeDialog}
        setShowPatientMergeDialog={setShowPatientMergeDialog}
        setShowPatientUndoMergeDialog={setShowPatientUndoMergeDialog}
      />

      <BannerContainer sx={{ minHeight: "170px" }}>
        <Box display="flex">
          <Box>
            <RenderPatientName
              deceased={["formal", "informal"].includes(
                props.patient.deathNotificationStatus ?? "",
              )}
              restricted={props.patient.restricted}
              familyName={props.patient.name.family}
              givenName={props.patient.name.given}
              styleType={mainTextStyles}
              fontSize={12}
            />
          </Box>

          <Box display="flex" alignItems={"flex-end"}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                {props.showNhsLabel && (
                  <>
                    <Spacer />
                    <RenderNhsNumber nhsNumber={props.nhsNumber} />
                  </>
                )}

                <Spacer />

                <RenderDateOfBirth
                  dateOfBirth={
                    props.patient.dateOfBirth
                      ? dayjs(props.patient.dateOfBirth).format("DD MMM YYYY")
                      : t("layout.patientBanner.unknown")
                  }
                  styleType={compactTextStyles}
                />

                <Spacer />
                <RenderPatientAge
                  patientAge={
                    patientAge ? patientAge : t("layout.patientBanner.unknown")
                  }
                  styleType={mainTextStyles}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ my: "0.5em" }}>
          <HorizontalLine noMargin={true} />
        </Box>
        <Box display="flex" alignItems={"center"}>
          <RenderPatientAddress
            address={props.patient.address.address}
            styleType={mainTextStyles}
          />

          <Box display="flex" alignItems={"flex-end"}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginTop: "0.5em",
                }}
              >
                <Spacer />
                <RenderPostCode
                  postalCode={props.patient.address.postalCode}
                  styleType={mainTextStyles}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </BannerContainer>

      {props.patientMhaStatusEnabled && (
        <Box sx={{ my: theme.spacing(2), ml: theme.spacing(2) }}>
          <RenderMhaStatusInfoBox
            mhaStatus={props.mhaStatus}
            showUnknownMhaStatus={props.showUnknownMhaStatus}
          />
        </Box>
      )}
    </Box>
  );
}

function RenderMergeDialogs({
  showPatientMergeDialog,
  showPatientUndoMergeDialog,
  setShowPatientMergeDialog,
  setShowPatientUndoMergeDialog,
  reloadPatientTimeline,
}: {
  showPatientMergeDialog: boolean;
  showPatientUndoMergeDialog: boolean;
  setShowPatientMergeDialog: (show: boolean) => void;
  setShowPatientUndoMergeDialog: (show: boolean) => void;
  reloadPatientTimeline?: () => void;
}) {
  return (
    <>
      {showPatientMergeDialog && (
        <PatientMergeDialog
          closeMergeDialog={() => setShowPatientMergeDialog(false)}
        />
      )}

      {showPatientUndoMergeDialog && (
        <PatientUndoMergeDialog
          closeUndoMergeDialog={() => setShowPatientUndoMergeDialog(false)}
          reloadPatientTimeline={reloadPatientTimeline}
        />
      )}
    </>
  );
}

function RenderPatientAddress({
  address,
  styleType,
}: {
  address: string;
  styleType: MainTextStyles;
}) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography sx={getSmallTextStyles}>
        {t("layout.patientBanner.address")}
      </Typography>
      <Typography sx={{ ...styleType }}>{address}</Typography>
    </Box>
  );
}

function RenderPatientAge({
  patientAge,
  styleType,
}: {
  patientAge: number | string;
  styleType: MainTextStyles;
}) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography sx={getSmallTextStyles}>
        {t("layout.patientBanner.age")}
      </Typography>
      <Typography sx={{ ...styleType }}>{patientAge}</Typography>
    </Box>
  );
}

function RenderDateOfBirth({
  dateOfBirth,
  styleType,
}: {
  dateOfBirth: string;
  styleType: MainTextStyles;
}) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography data-testid="dob-label" sx={getSmallTextStyles}>
        {t("layout.patientBanner.dateOfBirth")}
      </Typography>
      <Typography data-testid="dob" sx={{ ...styleType }}>
        {dateOfBirth}
      </Typography>
    </Box>
  );
}

function RenderPostCode({
  postalCode,
  styleType,
}: {
  postalCode: string;
  styleType: MainTextStyles;
}) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography data-testid="postcode-label" sx={getSmallTextStyles}>
        {t("layout.patientBanner.postcode")}
      </Typography>
      <Typography data-testid="postcode" sx={{ ...styleType }}>
        {postalCode ? postalCode : t("layout.patientBanner.unknown")}
      </Typography>
    </Box>
  );
}

export function TooltipInfoBox({
  title,
  icon,
}: {
  title: string;
  icon: JSX.Element;
}) {
  const theme = useTheme();
  return (
    <Tooltip
      arrow
      data-testid="info-tooltip"
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: "200px",
            backgroundColor: "common.white",
            color: "common.black",
            border: `2px solid ${theme.palette.common.lightGray}`,
          },
        },
        arrow: {
          sx: {
            color: "common.lightGray",
          },
        },
      }}
      title={title}
    >
      <span>{icon}</span>
    </Tooltip>
  );
}

function RenderPatientName({
  restricted,
  deceased,
  familyName,
  givenName,
  styleType,
  fontSize,
}: {
  deceased: boolean;
  restricted: boolean;
  familyName: string;
  givenName: string;
  styleType: MainTextStyles;
  fontSize: number;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <Box
        sx={{
          fontSize: theme.spacing(1.55),
          color: "primary.hint",
          marginBottom: "0.2em",
        }}
      >
        <Typography sx={{ ...getSmallTextStyles(theme) }}>
          {t("layout.patientBanner.name")}
        </Typography>
      </Box>
      <Typography
        data-testid="patient-name"
        sx={{
          ...styleType,
          fontWeight: 600,
          fontSize,
          display: "flex",
          justifyContent: "center",
          gap: "0.25em",
        }}
      >
        {familyName}, {givenName}{" "}
        {deceased && (
          <TooltipInfoBox
            title={
              "This patient has been marked as deceased in NHS national systems"
            }
            icon={<Info sx={{ fontSize: "1.5rem", ml: 1 }} />}
          />
        )}
        {restricted && (
          <TooltipInfoBox
            title={
              "This patient is marked as restricted in NHS national systems"
            }
            icon={
              <GppMaybeOutlined
                sx={{ color: "red", fontSize: "1.5rem", ml: 1 }}
              />
            }
          />
        )}
      </Typography>
    </>
  );
}

function RenderNhsNumber({ nhsNumber }: { nhsNumber?: string }) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        data-testid="nhs-number-label"
        sx={{ ...getSmallTextStyles(theme) }}
      >
        {t("layout.patientBanner.nhsNumber")}
      </Typography>

      <Typography data-testid="nhs-number" sx={{ ...compactTextStyles(theme) }}>
        {NhsNumberSchema.catch(t("common.unknown")).parse(nhsNumber)}
      </Typography>
    </Box>
  );
}

function RenderMhaStatusInfoBox({
  mhaStatus,
  showUnknownMhaStatus,
}: {
  mhaStatus:
    | {
        states: PatientStateWithContext[];
      }
    | undefined;
  showUnknownMhaStatus?: boolean;
}) {
  if (mhaStatus?.states[0] && !showUnknownMhaStatus) {
    return (
      <MhaStatusInfoBox
        title="MHA Status"
        subtitle={infoBoxMhaStatusTitle(
          mhaStatus.states[0],
          getActiveMhaStatus(mhaStatus.states[0].state!, dayjs().toISOString()),
        )}
        testId="mhaStatus"
      />
    );
  } else {
    return (
      <MhaStatusInfoBox title="MHA status" subtitle={"UNKNOWN (INCOMPLETE)"} />
    );
  }
}
