import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React from "react";
import {
  Banner,
  BannerList,
  Button,
  FormTitle,
  PopupDialog,
} from "~/components/design-system/index.js";

export function ConfirmDialog({
  onConfirm,
  closeDialog,
  isOpen,
  formTitleSubtitleText,
  warningMessage,
}: {
  onConfirm: () => Promise<void>;
  closeDialog: () => void;
  isOpen: boolean;
  formTitleSubtitleText: string;
  warningMessage: string;
}) {
  const [confirmButtonDisabled, setConfirmButtonDisabled] =
    React.useState(false);

  const handleConfirmClick = async () => {
    // Disable the button to prevent multiple clicks
    setConfirmButtonDisabled(true);

    try {
      await onConfirm();
    } finally {
      // Re-enable the button if the onConfirm function fails
      setConfirmButtonDisabled(false);
    }
  };

  return (
    <PopupDialog open={isOpen} onClose={closeDialog} sx={{ p: 1 }}>
      <DialogTitle sx={{ m: 0, p: 0 }}>
        <Box sx={{ mb: 3 }}>
          <FormTitle
            hasContainerMarginBottom={false}
            hasTitleBottomMargin
            subtitleText={formTitleSubtitleText}
          />
        </Box>
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: "absolute",
            right: 24,
            top: 24,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ my: 2 }}>
        <Banner bannerType={BannerList.WARNING} title={warningMessage} />
      </DialogContent>

      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button label="Go back" variant="outlined" onClick={closeDialog} />
        <Button
          color="primary"
          disabled={confirmButtonDisabled}
          onClick={handleConfirmClick}
          label="Confirm & Proceed"
        />
      </DialogActions>
    </PopupDialog>
  );
}
