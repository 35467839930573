import {
  acdV1Base,
  admissionTemplateIds,
  baseTemplates,
  capitalize,
  CreateExternalPatientDemograhicsPullEventRequest,
  doesUserMeetRequirement,
  ExternalPatientLink,
  findFormsMerged,
  FormContextData,
  isGuestUserSession,
  m2V1Base,
  PatientTimelineResponse,
  s23V1Base,
} from "@aspire/common";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Collapse,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { firstBy } from "thenby";
import { v4 } from "uuid";
import {
  Banner,
  BannerList,
  Button,
  FormTitle,
  PopupDialog,
  PopupDialogTitle,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system/index.js";
import { FormFooterSection } from "~/components/form/index.js";
import {
  PatientBanner,
  PatientBannerProps,
} from "~/components/layout/index.js";
import { Container, ExpandMore } from "~/components/layout/styleWrappers.js";
import { api } from "../api.js";
import { FormCategoryRow } from "../components/FormCategoryRow.js";
import { LoggedInUserContext } from "../Contexts.js";
import { usePatientTimeline } from "../hooks/apiCalls.js";
import { useExternalPatientLinks } from "../hooks/ExternalPatientLink/useExternalPatientLinks.js";
import { routeFns } from "../routes.js";
import { DefaultPageProps } from "./defaultProps.js";
import { PatientActivityLog } from "./FormProgressPage/helpers/ActivityLogs/PatientActivityLog.js";
import { FormContextPdfViewer } from "./FormProgressPage/helpers/FormContextPdfViewer.js";
import { NewFormDialog } from "./NewFormDialog.js";
import { PatientTimelineCard } from "./PatientTimelineCard.js";

interface FormAcc {
  [form: string]: {
    category: string;
    formName: string;
    description: string;
    section: string[];
  };
}

function PatientFormLaunch({
  patientTimeline: { patient, inProgressWork },
  syncExternalPatientLink,
}: {
  patientTimeline: PatientTimelineResponse;
  syncExternalPatientLink?: (
    reason: CreateExternalPatientDemograhicsPullEventRequest["reason"],
  ) => Promise<void>;
}) {
  const navigate = useNavigate();

  const isNotAdmissionTemplate = (template: { id: string }): boolean => {
    return !admissionTemplateIds.includes(template.id);
  };

  const { category } = useParams();

  const categoryCaps = category
    ? [
        TREATMENT_CATEGORY,
        HOSPITAL_CATEGORY,
        ADMISSION_CATEGORY,
        DISCHARGE_CATEGORY,
        MISCELLANEOUS_CATEGORY,
      ].includes(category)
      ? capitalize(category)
      : category.toUpperCase()
    : "";

  const existingAdmissions = inProgressWork.admissions;

  const existingWorkItems = inProgressWork.items;

  return (
    <Box display="flex">
      <NewFormDialog
        formTemplateFilterFn={isNotAdmissionTemplate}
        allowRequestForm={true}
        patientId={patient!.id}
        title={
          categoryCaps
            ? `Start ${
                categoryCaps === MISC ? acdV1Base.formName : categoryCaps
              } Form`
            : "Start Form"
        }
        closeDialog={() => navigate(routeFns.patientHome(patient.id))}
        existingAdmissions={existingAdmissions}
        existingWorkItems={existingWorkItems}
        syncExternalPatientLink={syncExternalPatientLink}
      />
    </Box>
  );
}

export function AdmissionWarningDialog({
  existingAdmissions,
  launchAdmission,
  patientId,
  launchNewFormFn,
  patientTimeline,
  syncExternalPatientLink,
}: {
  existingAdmissions?: Omit<FormContextData, "patient">[];
  launchAdmission?: () => void;
  patientId: string;
  patientTimeline?: PatientTimelineResponse;
  launchNewFormFn?: () => void;
  syncExternalPatientLink?: (
    reason: CreateExternalPatientDemograhicsPullEventRequest["reason"],
  ) => Promise<void>;
}) {
  const { t } = useTranslation();
  const [selectedRadioValue, setSelectedRadioValue] = React.useState(null);
  const [continueWarningMessage, setContinueWarningMessage] = useState("");

  const navigate = useNavigate();

  const launchStandardForm = launchNewFormFn && launchNewFormFn;
  const launchAdmissionForm = launchAdmission && launchAdmission;

  return (
    <PopupDialog
      open={true}
      fullWidth
      maxWidth="lg"
      testId="admission-warning-dialog"
    >
      <PopupDialogTitle
        titleText={t("pages.patientHome.launchForm.admissionProgressTitle")}
        closeDialog={() => navigate(routeFns.patientHome(patientId!))}
      />
      <Banner
        bannerType={BannerList.WARNING}
        title={t("pages.patientHome.launchForm.admissionWarningBannerTitle")}
      />
      {!!existingAdmissions?.length &&
        existingAdmissions.map((existingAdmission) => {
          const merged = findFormsMerged(
            patientTimeline?.mergedPatientData,
            existingAdmission,
          );
          return (
            <Box sx={{ mt: 2 }} key={existingAdmission.id}>
              <PatientTimelineCard
                formContext={existingAdmission!}
                showRadioSelector={existingAdmissions.length > 1}
                selectedRadioValue={selectedRadioValue!}
                setSelectedRadioValue={setSelectedRadioValue}
                lastMerged={merged?.lastMerged}
              />
            </Box>
          );
        })}
      {continueWarningMessage && !selectedRadioValue && (
        <Typography sx={{ color: "red", mb: 1 }}>
          {continueWarningMessage}
        </Typography>
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
        }}
      >
        <Button
          variant="outlined"
          label={t("buttonLabels.startNew")}
          testId="start-new-button"
          onClick={async () => {
            if (syncExternalPatientLink) {
              await syncExternalPatientLink("started form");
            }

            if (launchStandardForm) return launchStandardForm();
            if (launchAdmissionForm) return launchAdmissionForm();
          }}
        />
        <Button
          label={t("buttonLabels.continue")}
          disabled={!existingAdmissions}
          onClick={async () => {
            if (selectedRadioValue) {
              return (
                existingAdmissions &&
                navigate(
                  routeFns.formContextPage(selectedRadioValue!, patientId),
                )
              );
            }
            if (existingAdmissions?.length === 1) {
              const id = existingAdmissions[0].id;
              return navigate(routeFns.formContextPage(id, patientId), {
                replace: true,
              });
            }
            setContinueWarningMessage(
              t("pages.patientHome.launchForm.radioSelectorWarningMessage"),
            );
          }}
        />
      </Box>
    </PopupDialog>
  );
}

const TREATMENT_CATEGORY = "treatment";
const HOSPITAL_CATEGORY = "hospital";
const ADMISSION_CATEGORY = "admission";
const DISCHARGE_CATEGORY = s23V1Base.category;
const MISCELLANEOUS_CATEGORY = m2V1Base.category;
const MISC = acdV1Base.category.toLocaleUpperCase();

export function PatientHomePageInner({
  patientTimeline,
  mode,
  reloadPatientTimeline,
  externalPatientLinks,
  nhsNumber,
}: {
  patientTimeline: PatientTimelineResponse;
  reloadPatientTimeline: () => void;
  mode: "patient" | "form-launch";
  externalPatientLinks?: PatientBannerProps["externalPatientLinks"];
  nhsNumber?: string;
}) {
  const userContext = React.useContext(LoggedInUserContext);
  const { user } = userContext!;
  const navigate = useNavigate();
  const { patientId } = useParams();
  const location = useLocation();
  const [isAdmissionSelected, setIsAdmissionSelected] = useState(false);
  const [showOldForms, setShowOldForms] = useState(false);
  const [expandedForms, setExpandedForms] = useState(true);
  const [showInProgressForms, setShowInProgressForms] = useState(true);

  // HACK: ACD being enabled (for the KCL pilot) disables all other forms and just shows
  // ACD for now. T
  const isAcdEnabled = user.sessionOrganisationConfiguration?.acdEnabled;
  const areAdmissionsEnabled =
    user.sessionOrganisationConfiguration?.mhaAdmissionsEnabled;
  const enabledForms = user.sessionOrganisationConfiguration?.enabledForms;

  const { t } = useTranslation();

  const formContextId = useMemo(() => v4(), []);

  const [pdfViewFormId, setPdfViewFormId] = useState<null | string>(null);

  const existingAdmissions = patientTimeline.inProgressWork.admissions;

  const existingAdmissionsLength = !!existingAdmissions?.length;

  const launchAdmission = async () => {
    const result = await api.forms.createAdmissionsContext(
      formContextId,
      patientTimeline.patient.id,
    );

    if (result.status === 204) {
      renderSuccessToast({
        message: `Admission launched for ${patientTimeline.patient.name.given} ${patientTimeline.patient.name.family}`,
      });
      navigate(
        routeFns.formContextPage(formContextId, patientTimeline.patient.id),
      );
    } else {
      renderErrorToast({
        message: `Failed to launch admission form: ${
          (result.data as any).message
        }`,
      });
    }
  };

  const filteredTemplates = baseTemplates.filter(
    (t) =>
      !admissionTemplateIds.includes(t.id) &&
      enabledForms?.some((f) => f.id === t.id && f.version === t.version),
  );

  const templatesForUser = filteredTemplates.filter((f) => {
    return f.parts.some((part) =>
      doesUserMeetRequirement(user, part.signing.userOrTeamRequirement),
    );
  });

  const templates = isAcdEnabled
    ? templatesForUser
    : templatesForUser.filter((t) => t.id !== "acd");

  const uniqueTemplateCategories = Object.values(
    templates.reduce<FormAcc>(
      (acc, { category, formName, description, section }) => {
        acc[category] ??= { category, formName, description, section: [] };
        acc[category].section.push(section);
        return acc;
      },
      {},
    ),
  )
    // 136 forms are never directly launchable from patient page
    .filter(({ category }) => category !== "136")
    .map(({ category, ...rest }) => ({
      category: [
        TREATMENT_CATEGORY,
        HOSPITAL_CATEGORY,
        ADMISSION_CATEGORY,
        DISCHARGE_CATEGORY,
        MISCELLANEOUS_CATEGORY,
      ].includes(category)
        ? capitalize(category)
        : category.toUpperCase(),
      ...rest,
    }));

  const inProgressWorkItemsLength = patientTimeline.inProgressWork.items.length;

  const inProgressAdmissions = patientTimeline.inProgressWork?.admissions;

  const inProgressForms =
    !!inProgressWorkItemsLength || !!inProgressAdmissions?.length;
  const completedWorkCount = patientTimeline.completedWork.count;

  (inProgressAdmissions || []).sort(firstBy("updated", -1));

  const closePatientActivityLog = () => {
    navigate(routeFns.patientHome(patientId!));
  };

  // Activity Log modal
  const showActivityLogModal = location.pathname.startsWith(
    routeFns.patientHomeActivityLog(patientId!),
  );

  const syncExternalPatientLink = useCallback(
    async (
      reason: CreateExternalPatientDemograhicsPullEventRequest["reason"],
    ) => {
      if (externalPatientLinks !== undefined) {
        const externalPatientLink = externalPatientLinks.links.find(
          (l) => l.canPull,
        );

        if (externalPatientLink) {
          await externalPatientLinks.onSync(externalPatientLink, reason);
        }
      }
    },
    [externalPatientLinks],
  );

  const MAX_VISIBLE_FORMS = 10;
  const [showAll, setShowAll] = useState(false);

  const visibleForms = showAll
    ? patientTimeline.completedWork.items
    : patientTimeline.completedWork.items.slice(0, MAX_VISIBLE_FORMS);

  return (
    <>
      <FormTitle
        titleText={t("pages.patientHome.title")}
        useReducedTopPadding={true}
        hasTitleBottomMargin={false}
      />
      <PatientBanner
        patient={patientTimeline.patient}
        patientTimeline={patientTimeline}
        nhsNumber={nhsNumber ?? undefined}
        reloadPatientTimeline={reloadPatientTimeline}
        externalPatientLinks={externalPatientLinks}
      />
      {uniqueTemplateCategories.length > 0 ? (
        <Container addMarginBottom>
          <Box display={"flex"} alignItems="center" sx={{ mb: 2 }}>
            <FormTitle
              subtitleText={t("common.newForm")}
              subTitleTextFontSize={2}
              hasContainerMarginBottom={false}
            />
            <ExpandMore
              role="button"
              isExpanded={expandedForms}
              onClick={() => setExpandedForms(!expandedForms)}
              aria-expanded={expandedForms}
              aria-label={t("components.cardExtended.expandMoreLabel")}
            >
              <ExpandMoreIcon sx={{ color: "primary.main" }} />
            </ExpandMore>
          </Box>
          <Collapse in={expandedForms} timeout="auto" unmountOnExit>
            {!isGuestUserSession(userContext?.user) && areAdmissionsEnabled && (
              <FormCategoryRow
                title={t("pages.patientHome.admissionFormTitle")}
                subtitle={`${t("pages.patientHome.section")} 2, 3 ${t(
                  "common.and",
                )} 4`}
                launchFormLabel={t("pages.patientHome.launchForm.startForm")}
                buttonTestId="start-form-button--admissions"
                onLaunchForm={async () => {
                  if (!existingAdmissionsLength) {
                    await syncExternalPatientLink("started form");
                    return launchAdmission();
                  }

                  navigate(routeFns.patientFormLaunch(patientId!));
                  setIsAdmissionSelected(true);
                }}
              />
            )}
            {uniqueTemplateCategories.map((u) => (
              <FormCategoryRow
                key={u.description}
                title={
                  u.category === MISC
                    ? `${u?.description}`
                    : `${u?.category} ${t("common.forms")}`
                }
                subtitle={
                  u.category === MISC
                    ? `${t("pages.patientHome.section")} ${u?.section}`
                    : `${t("pages.patientHome.section")} ${u?.section
                        .map((s) => (s === "2, 3 and 4" ? "H3" : s))
                        .filter((s) => s)
                        .join(", ")}`
                }
                launchFormLabel={t("pages.patientHome.launchForm.startForm")}
                buttonTestId={`start-form-button--${u.category.toLowerCase()}`}
                onLaunchForm={() => {
                  const category = u.category.toLowerCase();
                  navigate(routeFns.patientFormLaunch(patientId!, category));
                  setIsAdmissionSelected(false);
                }}
              />
            ))}
          </Collapse>
        </Container>
      ) : (
        <> </>
      )}

      {mode === "form-launch" && (
        <>
          {isAdmissionSelected ? (
            <AdmissionWarningDialog
              existingAdmissions={existingAdmissions}
              launchAdmission={launchAdmission}
              patientId={patientId!}
              patientTimeline={patientTimeline}
              syncExternalPatientLink={syncExternalPatientLink}
            />
          ) : (
            <PatientFormLaunch
              patientTimeline={patientTimeline}
              syncExternalPatientLink={syncExternalPatientLink}
            />
          )}
        </>
      )}
      {pdfViewFormId && (
        <FormContextPdfViewer
          inModal={true}
          forms={[
            ...(patientTimeline.inProgressWork.admissions || []),
            ...patientTimeline.inProgressWork.items,
            ...patientTimeline.completedWork.items,
          ]
            .find((i) => i.forms.some((f) => f.id === pdfViewFormId))!
            .forms.filter(
              (f) =>
                f.linkedForms.length > 0 ||
                !admissionTemplateIds.includes(f.formTemplate.id),
            )}
          setFormId={setPdfViewFormId}
          formId={pdfViewFormId}
        />
      )}
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        {inProgressForms && (
          <Container addMarginBottom>
            <Box display={"flex"} alignItems="center" sx={{ mb: 2 }}>
              <FormTitle
                subtitleText={`In Progress Forms (${
                  inProgressWorkItemsLength + (existingAdmissions?.length || 0)
                })`}
                subTitleTextFontSize={2}
                hasContainerMarginBottom={false}
              />
              <ExpandMore
                role="button"
                isExpanded={showInProgressForms}
                onClick={() => setShowInProgressForms(!showInProgressForms)}
                aria-expanded={showInProgressForms}
                aria-label={t("components.cardExtended.expandMoreLabel")}
              >
                <ExpandMoreIcon sx={{ color: "primary.main" }} />
              </ExpandMore>
            </Box>
            {showInProgressForms && (
              <Box sx={{ mt: 2 }}>
                {inProgressAdmissions &&
                  inProgressAdmissions.map((admission, index) => {
                    const merged = findFormsMerged(
                      patientTimeline?.mergedPatientData,
                      admission,
                    );
                    return (
                      <PatientTimelineCard
                        key={index}
                        formContext={admission}
                        setPdfViewFormId={setPdfViewFormId}
                        lastMerged={merged?.lastMerged}
                      />
                    );
                  })}
                {patientTimeline.inProgressWork.items.map((formContext) => {
                  const merged = findFormsMerged(
                    patientTimeline?.mergedPatientData,
                    formContext,
                  );

                  return (
                    <PatientTimelineCard
                      key={formContext.id}
                      formContext={formContext}
                      setPdfViewFormId={setPdfViewFormId}
                      lastMerged={merged?.lastMerged}
                    />
                  );
                })}
              </Box>
            )}
          </Container>
        )}
      </Box>

      {completedWorkCount > 0 && (
        <Container>
          <Box display={"flex"} alignItems="center" sx={{ mb: 2 }}>
            <FormTitle
              hasContainerMarginBottom={false}
              subtitleText={`Old forms (${completedWorkCount})`}
              subTitleTextFontSize={2}
            />
            <ExpandMore
              role="button"
              isExpanded={showOldForms}
              onClick={() => setShowOldForms(!showOldForms)}
              aria-expanded={showOldForms}
              aria-label={t("components.cardExtended.expandMoreLabel")}
            >
              <ExpandMoreIcon sx={{ color: "primary.main" }} />
            </ExpandMore>
          </Box>
          {showOldForms &&
            visibleForms.map((formContext) => {
              const merged = findFormsMerged(
                patientTimeline?.mergedPatientData,
                formContext,
              );

              return (
                <Box key={formContext.id} sx={{ mt: 3 }}>
                  <PatientTimelineCard
                    formContext={formContext}
                    setPdfViewFormId={setPdfViewFormId}
                    lastMerged={merged?.lastMerged}
                  />
                </Box>
              );
            })}

          {showOldForms &&
            patientTimeline.completedWork.items.length > MAX_VISIBLE_FORMS && (
              <Box display="flex" justifyContent="center">
                <Button
                  label={
                    showAll
                      ? t("pages.patientHome.viewLess")
                      : t("pages.patientHome.viewMore")
                  }
                  onClick={() => setShowAll(!showAll)}
                  variant="contained"
                  sx={{ mt: 2 }}
                />
              </Box>
            )}
        </Container>
      )}
      {showActivityLogModal && (
        <PatientActivityLog
          patientId={patientId!}
          user={user}
          onClose={closePatientActivityLog}
        />
      )}
      <FormFooterSection
        hideSubmit={true}
        isSticky={true}
        onSave={() => {}}
        disableSubmit={true}
        saveLabel={t("buttonLabels.send")}
        discardLabel={t("buttonLabels.patientLog")}
        onCancel={() => {
          navigate(routeFns.patientHomeActivityLog(patientId!));
        }}
      />
    </>
  );
}

export function PatientHomePage({}: DefaultPageProps) {
  const { patientId, externalLinkId: externalLinkIdFromParams } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const page = 0;

  const { patientTimeline, response, reloadPatientTimeline } =
    usePatientTimeline({
      patientId: patientId,
      page: page,
      limit: 1000,
    });

  const {
    externalPatientLinks,
    externalPatientLinksLoading,
    syncExternalPatientLink,
    reloadExternalPatientLinks,
    Component: ExternalPatientLinkComponents,
    patientForReview: hasShownPatientForReview,
  } = useExternalPatientLinks({
    patientId: patientId!,
  });

  const nhsNumber = patientTimeline?.patient?.nhsNumber;

  const [mode, setMode] = useState<"patient" | "form-launch">("patient");

  const { pathname } = useLocation();

  React.useEffect(() => {
    if (pathname.includes("form-launch")) {
      setMode("form-launch");
    } else {
      setMode("patient");
    }
  }, [pathname]);

  React.useEffect(() => {
    if (response && response.status === 404) {
      navigate(routeFns.notFound());
    }
  }, [response, patientId, navigate]);

  const onSync = React.useCallback(
    async (
      externalPatientLink: ExternalPatientLink,
      reason: CreateExternalPatientDemograhicsPullEventRequest["reason"],
    ) => {
      await syncExternalPatientLink(externalPatientLink, reason);
      reloadPatientTimeline();
      reloadExternalPatientLinks();
    },
    [syncExternalPatientLink],
  );

  React.useEffect(() => {
    if (
      response?.status === 200 &&
      externalPatientLinksLoading === false &&
      externalPatientLinks &&
      externalLinkIdFromParams &&
      patientId
    ) {
      const link = externalPatientLinks.externalLinks.find(
        (l) => l.id === externalLinkIdFromParams,
      );

      if (!link) {
        navigate(routeFns.patientHome(patientId), { replace: true });
      } else {
        onSync(link, "click through")
          .then(() => {
            navigate(routeFns.patientHome(patientId), { replace: true });
          })
          .catch(() => {
            navigate(routeFns.patientHome(patientId), { replace: true });
          });
      }
    }
  }, [
    onSync,
    externalPatientLinksLoading,
    externalPatientLinks,
    externalLinkIdFromParams,
    response?.status,
    patientId,
    navigate,
  ]);

  // Show a loading spinner while loading from the external system
  if (externalLinkIdFromParams) {
    return hasShownPatientForReview ? (
      <ExternalPatientLinkComponents />
    ) : (
      <Stack
        gap="2rem"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Typography variant="h5">
          {t("pages.patientHome.connectingToExternalSystem")}
        </Typography>
        <CircularProgress />
        <Link to={routeFns.patientHome(patientId!)}>
          {t("pages.patientHome.returnToPatientHome")}
        </Link>
      </Stack>
    );
  }

  return !(response && response.status === 200) ? (
    <></>
  ) : (
    <>
      <ExternalPatientLinkComponents />
      <PatientHomePageInner
        patientTimeline={patientTimeline!}
        mode={mode}
        reloadPatientTimeline={reloadPatientTimeline}
        nhsNumber={nhsNumber ?? undefined}
        externalPatientLinks={
          externalPatientLinksLoading === false &&
          externalPatientLinks?.externalLinks !== undefined
            ? {
                links: externalPatientLinks?.externalLinks,
                onSync,
              }
            : undefined
        }
      />
    </>
  );
}
