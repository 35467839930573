import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { routeFns } from "../../routes.js";
import { PatientCreateEdit } from "./PatientCreateEdit.js";

import { ExtendedPatient } from "@aspire/common";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Banner,
  BannerList,
  Button,
  PopupDialog,
  PopupDialogTitle,
} from "~/components/design-system/index.js";
import { isValidPatientDemographics } from "~/components/util.js";
import { WithPatient } from "../../hoc/WithPatient.js";
import { BannerDialog } from "../FormProgressPage/helpers/BannerDialog.js";

export type editPatientDialog = "editPatientDialog";

type PatientEditProps = {
  mode?: editPatientDialog;
  refetchDraft?: () => void;
};

function MissingDemographicsDialog({
  open,
  onClose,
  restricted,
}: {
  open: boolean;
  onClose: () => void;
  restricted: boolean;
}) {
  return (
    <PopupDialog open={open} onClose={onClose}>
      <PopupDialogTitle
        titleText={
          restricted
            ? `Patient is restricted`
            : "Patient is missing key demographics"
        }
        closeDialog={onClose}
      />
      <Banner
        bannerType={BannerList.ERROR}
        title={
          restricted
            ? "This patient is marked as restricted in NHS national systems - please add any missing demographics before continuing"
            : "This patient is missing key demographics required for the completion of forms on eMHA - please add any missing demographics before continuing"
        }
      />
      <Box
        sx={{
          width: "100%",
          marginTop: "1rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button label="Close" onClick={onClose} />
      </Box>
    </PopupDialog>
  );
}

function PatientEditInner({
  mode,
  refetchDraft,
  patient,
  setShowUpdateSuccessDialog,
}: PatientEditProps & {
  patient: ExtendedPatient;
  setShowUpdateSuccessDialog: (value: boolean) => void;
}) {
  const { patientId, formDraftId } = useParams();
  const { t } = useTranslation();

  const isPatientMissingDemographics = !isValidPatientDemographics(patient);

  const [showMissingDemographicsDialog, setShowMissingDemographicsDialog] =
    React.useState(isPatientMissingDemographics);

  const navigate = useNavigate();
  const refetchAndRedirect = () => {
    navigate(routeFns.patientEditDialogSuccess(formDraftId!, patientId!));
    refetchDraft && refetchDraft();
  };

  return (
    <Box>
      <MissingDemographicsDialog
        open={showMissingDemographicsDialog}
        onClose={() => setShowMissingDemographicsDialog(false)}
        restricted={patient.restricted}
      />
      <PatientCreateEdit
        existingPatient={patient}
        nhsNumber={patient.nhsNumber ?? undefined}
        refetchAndRedirect={refetchAndRedirect}
        setShowUpdateSuccessDialog={setShowUpdateSuccessDialog}
        mode={mode ?? "edit"}
        existingPatientId={patientId}
        expectedVersion={patient.version}
        exitPatientCreate={() => {
          if (patientId) {
            if (mode === "editPatientDialog") {
              navigate(routeFns.formDraftsComplete(formDraftId!, patientId!));
            } else {
              navigate(routeFns.patientHome(patientId));
            }
          }
        }}
        initialState={{
          givenName: patient.name.given,
          familyNameAndAddress: {
            name: patient.name.family,
            postalCode: patient.address.postalCode,
            address: patient.address.address,
            isConfirmed: true,
          },
          isPatientDateOfBirth: patient.dateOfBirth ? true : false,
          dateOfBirth: patient.dateOfBirth ?? null,
          deathNotificationStatus: patient.deathNotificationStatus,
        }}
      />
    </Box>
  );
}

export function PatientEditPage({ mode, refetchDraft }: PatientEditProps) {
  const { patientId } = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [showUpdateSuccessDialog, setShowUpdateSuccessDialog] =
    React.useState(false);
  const navigate = useNavigate();

  return (
    <>
      <WithPatient patientId={patientId}>
        {(patient) => {
          return (
            <PatientEditInner
              patient={patient}
              mode={mode}
              refetchDraft={refetchDraft}
              setShowUpdateSuccessDialog={setShowUpdateSuccessDialog}
            />
          );
        }}
      </WithPatient>
      {showUpdateSuccessDialog && mode !== "editPatientDialog" && (
        <BannerDialog
          title={t("pages.patientEditCreate.updateSuccess")}
          message={t("pages.patientEditCreate.updateSuccessDialogMessage")}
          onClose={() => {
            if (searchParams.has("redirectTo")) {
              navigate(searchParams.get("redirectTo")!);
            } else {
              navigate(routeFns.patientHome(patientId!));
            }
          }}
        />
      )}
    </>
  );
}
