import {
  isGuestUserSession,
  nonEmptyRequiredString,
  VisionAcceptIncidentRequest,
} from "@aspire/common";
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ShareableIncidentDataV1Response } from "@thalamos/common";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { api, apiHooks, logout } from "~/api.js";
import {
  Banner,
  BannerList,
  Button,
  FormTitle,
  PopupDialog,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system/index.js";
import { LoggedInUserContext } from "~/Contexts.js";
import { routeFns } from "~/routes.js";
import { ukLocalFormatDate } from "~/util.js";

export type VisionIncidentPreviewPageProps = {};

const ProfileCard = ({
  patientDetails,
}: {
  patientDetails: ShareableIncidentDataV1Response["patientDetails"];
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={3}>
          <Typography variant="body2" color="textSecondary">
            {t("pages.incidentPreviewPage.name")}
          </Typography>
          <Typography variant="subtitle1" fontWeight="bold">
            {patientDetails.name.given} {patientDetails.name.family}
          </Typography>
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
        <Grid item xs={3}>
          <Typography variant="body2" color="textSecondary">
            {t("pages.incidentPreviewPage.dateOfBirth")}
          </Typography>
          <Typography variant="subtitle1">
            {patientDetails.dateOfBirth
              ? ukLocalFormatDate(patientDetails.dateOfBirth)
              : "Unknown"}
          </Typography>
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
        <Grid item xs={3}>
          <Typography variant="body2" color="textSecondary">
            {t("pages.incidentPreviewPage.address")}
          </Typography>
          <Typography variant="subtitle1">
            {patientDetails.address.address}
          </Typography>
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
        <Grid item xs={1}>
          <Typography variant="body2" color="textSecondary">
            {t("pages.incidentPreviewPage.postcode")}
          </Typography>
          <Typography variant="subtitle1">
            {patientDetails.address.postcode}
          </Typography>
        </Grid>
      </Grid>
      <Divider orientation="horizontal" flexItem sx={{ my: 2 }} />
    </>
  );
};

const validationSchema = yup.object({
  placeOfSafety: yup.string().when("manualEntry", {
    is: (manualEntry: boolean) => manualEntry === true,
    then: (s) =>
      nonEmptyRequiredString.required(
        "Please enter your SWE registration number",
      ),
  }),
  manualPosName: yup.string().when("manualEntry", {
    is: (manualEntry: boolean) => manualEntry === true,
    then: (s) => nonEmptyRequiredString.required("name is required"),
  }),
  manualPosAddress: yup.string().when("manualEntry", {
    is: (manualEntry: boolean) => manualEntry === true,
    then: (s) => nonEmptyRequiredString.required("address is required"),
  }),
  manualPosPostcode: yup.string().when("manualEntry", {
    is: (manualEntry: boolean) => manualEntry === true,
    then: (s) => nonEmptyRequiredString.required("postcode is required"),
  }),
});

export function VisionIncidentPreviewPage() {
  const userContext = React.useContext(LoggedInUserContext);

  const { t } = useTranslation();
  const incidentId = new URLSearchParams(window.location.search).get(
    "incident-id",
  );

  const navigate = useNavigate();
  const [{ loading, data: incidentPreviewData }] =
    apiHooks.vision.previewIncident(incidentId ?? "");

  React.useEffect(() => {
    if (!incidentId) {
      renderErrorToast({
        message:
          "Incident ID not found. Please try to login through share code/email link again",
      });
      navigate(routeFns.home());
    }
  }, []);

  // if we have already accepted then redirect to the form context page
  React.useEffect(() => {
    if (incidentPreviewData && "formContextId" in incidentPreviewData) {
      navigate(
        routeFns.formContextPage(
          incidentPreviewData.formContextId,
          incidentPreviewData.patientId,
        ),
        { replace: true },
      );
    }
  }, [incidentPreviewData]);

  if (
    loading ||
    !incidentPreviewData ||
    !("patientDetails" in incidentPreviewData)
  ) {
    return <CircularProgress />;
  }

  const activePlaceOfSafety = incidentPreviewData?.placesOfSafety.find(
    (p) => p.isActive,
  );

  const initialValues = {
    placeOfSafety: "",
    selectedPlaceOfSafety: "",
    manualEntry: false,
    manualPosName: "",
    manualPosAddress: "",
    manualPosPostcode: "",
  };

  return (
    <PopupDialog
      open
      title={t("pages.incidentPreviewPage.incidentPreviewTitle")}
      fullWidth
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          let selectedPos:
            | VisionAcceptIncidentRequest["placeOfSafety"]
            | undefined = undefined;

          // Did the user select an emha-managed place of safety?
          const selectedCuratedPos =
            incidentPreviewData?.curatedPlacesOfSafety?.find(
              (pos) => pos.id === values.selectedPlaceOfSafety,
            );

          // Did this emha-managed place of safety exist on the incident already
          const matchingIncidentPos = selectedCuratedPos
            ? incidentPreviewData?.placesOfSafety.find(
                (pos) =>
                  pos.eMHACuratedPlaceOfSafetyId === selectedCuratedPos.id,
              )
            : null;

          if (values.manualEntry) {
            // or was it manually entered
            // (no ods code or curated place of safety id)
            selectedPos = {
              name: values.manualPosName,
              address: values.manualPosAddress,
              postalCode: values.manualPosPostcode,
            };
          } else if (matchingIncidentPos) {
            selectedPos = {
              id: matchingIncidentPos.id,
              name: matchingIncidentPos.name,

              address: matchingIncidentPos.address,
              postalCode: matchingIncidentPos.postalCode,

              eMHACuratedPlaceOfSafetyId:
                matchingIncidentPos.eMHACuratedPlaceOfSafetyId,

              odsCode: matchingIncidentPos.odsCode,
            };
          } else if (selectedCuratedPos) {
            selectedPos = {
              name: selectedCuratedPos.name,
              address: selectedCuratedPos.address,
              postalCode: selectedCuratedPos.postalCode,

              eMHACuratedPlaceOfSafetyId: selectedCuratedPos.id,
              odsCode: selectedCuratedPos.odsCode,
            };
          }
          // if none of the above then they just
          // accepted the currently active incident place of safety
          else if (activePlaceOfSafety) {
            selectedPos = {
              id: activePlaceOfSafety.id,
              name: activePlaceOfSafety.name,
              address: activePlaceOfSafety.address,
              postalCode: activePlaceOfSafety.postalCode,
              eMHACuratedPlaceOfSafetyId:
                activePlaceOfSafety.eMHACuratedPlaceOfSafetyId,
              odsCode: activePlaceOfSafety.odsCode,
            };
          }

          if (!selectedPos) {
            renderErrorToast({
              message: t(
                "pages.incidentPreviewPage.selectPlaceOfSafetyMessage",
              ),
            });
            setSubmitting(false);
            return;
          }

          setSubmitting(true);
          try {
            const res = await api.vision.acceptIncident({
              incidentId: incidentId ?? "",
              placeOfSafety: selectedPos,
            });

            if (res.status !== 200) {
              renderErrorToast({
                message: t("pages.incidentPreviewPage.failedAcceptIncident"),
              });
            } else {
              navigate(
                routeFns.formContextPage(
                  res.data.formContextId,
                  res.data.patientId,
                ),
                { replace: true },
              );
            }
          } catch (e) {
            renderErrorToast({
              message: t("pages.incidentPreviewPage.failedAcceptIncident"),
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          isSubmitting,
          setSubmitting,
          setFieldValue,
          errors,
          touched,
        }) => {
          const isConfirmDisabled =
            values.placeOfSafety === null ||
            (values.placeOfSafety === "no" &&
              !values.manualEntry &&
              !values.selectedPlaceOfSafety) ||
            (values.manualEntry &&
              (!values.manualPosName.trim() ||
                !values.manualPosAddress.trim() ||
                !values.manualPosPostcode.trim()));

          return (
            <Form>
              <Stack sx={{ margin: "0.5rem", display: "flex" }}>
                <FormTitle
                  useReducedTopPadding={true}
                  hasTitleBottomMargin={false}
                  titleText={t("pages.incidentPreviewPage.confirmDetails")}
                />
                <Typography variant="body1">
                  {t("pages.incidentPreviewPage.incidentOfficer", {
                    name: incidentPreviewData.officerDetails.name,
                  })}
                </Typography>
                <Box sx={{ mt: "2rem" }}>
                  <ProfileCard
                    patientDetails={incidentPreviewData.patientDetails}
                  />
                </Box>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {t("pages.incidentPreviewPage.formControlLabel")}
                  </FormLabel>
                  <Typography sx={{ fontWeight: "bold", mt: "1rem" }}>
                    {activePlaceOfSafety?.name}
                  </Typography>
                  <Typography sx={{ mb: "1rem" }}>
                    {activePlaceOfSafety?.address},{" "}
                    {activePlaceOfSafety?.postalCode}
                  </Typography>
                  <RadioGroup
                    value={values.placeOfSafety}
                    onChange={(e) => {
                      setFieldValue("placeOfSafety", e.target.value);
                      setFieldValue("selectedPlaceOfSafety", "");
                      setFieldValue("manualEntry", false);
                      setFieldValue("manualPosName", "");
                      setFieldValue("manualPosAddress", "");
                      setFieldValue("manualPosPostcode", "");
                    }}
                    name="placeOfSafety"
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label={t("common.yes")}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label={t("common.no")}
                    />
                  </RadioGroup>
                </FormControl>

                {/* Show dropdown only if 'No' is selected */}
                {values.placeOfSafety === "no" && (
                  <>
                    <FormControl fullWidth sx={{ mt: "1rem" }}>
                      <FormLabel>
                        {t("pages.incidentPreviewPage.dropdownPosTitle")}
                      </FormLabel>
                      <Select
                        name="selectedPlaceOfSafety"
                        value={values.selectedPlaceOfSafety}
                        onChange={(e) => {
                          setFieldValue(
                            "selectedPlaceOfSafety",
                            e.target.value,
                          );
                          // If 'no' is selected, the dropdown should remain visible
                        }}
                        displayEmpty
                        disabled={values.manualEntry === true}
                      >
                        <MenuItem value="" disabled>
                          {t(
                            "pages.incidentPreviewPage.dropdownItemPlaceholder",
                          )}
                        </MenuItem>
                        {incidentPreviewData?.curatedPlacesOfSafety?.map(
                          (pos) => (
                            <MenuItem key={pos.id} value={pos.id}>
                              {pos.name}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>

                    {/* Checkbox to manually enter data */}
                    <FormControlLabel
                      sx={{ mt: "1rem" }}
                      control={
                        <Checkbox
                          checked={values.manualEntry}
                          onChange={(e) => {
                            setFieldValue("manualEntry", e.target.checked);
                            setFieldValue("manualPosName", "");
                            setFieldValue("manualPosAddress", "");
                            setFieldValue("manualPosPostcode", "");
                            setFieldValue("selectedPlaceOfSafety", "");
                          }}
                        />
                      }
                      label={t("pages.incidentPreviewPage.manuallyEnter")}
                    />

                    {/* Display text fields if "Manually enter" is checked */}
                    {values.manualEntry && (
                      <Box sx={{ mt: "1rem" }}>
                        <Field
                          name="manualPosName"
                          label={t("pages.incidentPreviewPage.name")}
                          fullWidth
                          as={TextField}
                          error={
                            touched.manualPosName && !!errors.manualPosName
                          }
                          helperText={
                            touched.manualPosName && errors.manualPosName
                          }
                        />
                        <Field
                          name="manualPosAddress"
                          label={t("pages.incidentPreviewPage.address")}
                          fullWidth
                          sx={{ mt: "1rem" }}
                          as={TextField}
                          error={
                            touched.manualPosAddress &&
                            !!errors.manualPosAddress
                          }
                          helperText={
                            touched.manualPosAddress && errors.manualPosAddress
                          }
                        />
                        <Field
                          name="manualPosPostcode"
                          label={t("pages.incidentPreviewPage.postcode")}
                          fullWidth
                          sx={{ mt: "1rem" }}
                          as={TextField}
                          error={
                            touched.manualPosPostcode &&
                            !!errors.manualPosPostcode
                          }
                          helperText={
                            touched.manualPosPostcode &&
                            errors.manualPosPostcode
                          }
                        />
                      </Box>
                    )}
                    <Box sx={{ mt: "2rem" }}>
                      <Banner
                        bannerType={BannerList.WARNING}
                        title={t("pages.incidentPreviewPage.warningBannerText")}
                      />
                    </Box>
                  </>
                )}

                <Box
                  sx={{
                    mt: "2rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={async () => {
                      setSubmitting(true);
                      try {
                        const res = await api.vision.rejectIncident(
                          incidentId ?? "",
                          t("pages.incidentPreviewPage.receivedInError"),
                        );
                        if (res.status !== 204) {
                          renderErrorToast({
                            message: t(
                              "pages.incidentPreviewPage.failedRejectIncident",
                            ),
                          });
                        } else {
                          renderSuccessToast({
                            message: t(
                              "pages.incidentPreviewPage.incidentRejected",
                            ),
                          });
                          setTimeout(() => {
                            if (isGuestUserSession(userContext?.user)) {
                              logout();
                            }
                            navigate(routeFns.home());
                          }, 2000);
                        }
                      } catch (e) {
                        renderErrorToast({
                          message: "Failed to reject incident",
                        });
                      } finally {
                        setSubmitting(false);
                      }
                    }}
                    label={t("pages.incidentPreviewPage.receivedInError")}
                    testId="reject-incident-button"
                  />
                  <Button
                    type="submit"
                    label={t("buttonLabels.continue")}
                    disabled={isConfirmDisabled || isSubmitting}
                    testId="confirm-incident-button"
                  />
                </Box>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </PopupDialog>
  );
}
