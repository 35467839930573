import { isoDateString } from "@aspire/common";
import { Box, FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import dayjs from "dayjs";
import { Form, Formik, FormikConfig } from "formik";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { boolean, InferType, object, string } from "yup";
import {
  Dropdown,
  FormLabel,
  HelperText,
} from "~/components/design-system/index.js";
import { DateFormField } from "~/components/form/index.js";
import { TextboxFormField } from "~/components/form/TextboxFormField.js";
import { FieldProps } from "../FieldProps.js";
import { ValidationSchema as LocalDemographicsValidationSchema } from "./CollectCriteriaDemographics.js";

// all alphabetic characters from any language (\p{L}), dashes, apostrophes, and spaces
const nameCharacters =
  /^[\p{L}\s\p{Dash}\u2018\u2019\u2032\u0027\u02BC]{1,35}$/u;

export const validationSchema = object({
  givenName: string()
    .default(null)
    .nullable()
    .when([], {
      is: true,
      then: (s) => s.required("Please enter patient's forename"),
      otherwise: (s) => s.nullable(),
    })
    .matches(
      nameCharacters,
      "Name includes invalid characters (up to 35 letters, spaces, dashes and apostrophes are allowed)",
    ),

  familyName: string()
    .nullable()
    .default(null)
    .when([], {
      is: true,
      then: (s) => s.required("Please enter patient's surname"),
      otherwise: (s) => s.nullable(),
    })
    .matches(
      nameCharacters,
      "Name includes invalid characters (up to 35 letters, spaces, dashes and apostrophes are allowed)",
    ),

  postcode: string()
    .matches(/^[A-Za-z0-9\s]{2,8}$/, "Invalid postcode")
    .default(null)
    .nullable(),

  dateOfBirthIsExact: boolean()
    .nullable()
    .default(null)
    .when([], {
      is: true,
      then: (s) => s.required("Please provide information about date of birth"),
      otherwise: (s) => s.nullable(),
    }),

  dateOfBirthExact: string()
    .nullable()
    .default(null)
    .when("dateOfBirthIsExact", {
      is: true,
      then: () => isoDateString.required("Please enter a valid time / date"),
      otherwise: (s) => s.nullable(),
    }),

  ageApprox: string()
    .nullable()
    .default(null)
    .when("dateOfBirthIsExact", {
      is: false,
      then: (s) => s.required("Please enter a valid age range"),
      otherwise: (s) => s.nullable(),
    }),
});

export type PdsValidationSchema = InferType<typeof validationSchema>;

export type CollectCriteriaPdsDemographicsProps = Pick<
  FormikConfig<PdsValidationSchema>,
  "onSubmit" | "children"
> & {
  disabled?: boolean;
  lastSearchedDemographics: LocalDemographicsValidationSchema | undefined;
};

function WithDefaultValues(props: {
  setValues: (values: PdsValidationSchema) => void;
  default: PdsValidationSchema;
}) {
  useEffect(() => {
    props.setValues(props.default);
  }, [JSON.stringify(props.default)]);

  return <></>;
}

export const CollectCriteriaPdsDemographics = ({
  onSubmit,
  children,
  disabled,
  lastSearchedDemographics,
}: CollectCriteriaPdsDemographicsProps) => {
  const { t } = useTranslation();
  const ageRange = useAgeRange();

  // Apply the transformations on the schema to the submitted data
  const scopedOnSubmit = useCallback<
    FormikConfig<PdsValidationSchema>["onSubmit"]
  >(
    (values, formikHelpers) =>
      onSubmit(validationSchema.cast(values), formikHelpers),
    [onSubmit],
  );

  return (
    <Formik<PdsValidationSchema>
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      initialValues={validationSchema.getDefault()}
      onSubmit={scopedOnSubmit}
    >
      {(formikProps) => {
        const fieldProps: FieldProps<PdsValidationSchema> = {
          ...formikProps,
          validationSchema: validationSchema,
          context: {},
        };

        return (
          <Form>
            <WithDefaultValues
              setValues={formikProps.setValues}
              default={{
                ...validationSchema.getDefault(),
                ...lastSearchedDemographics,
              }}
            />
            <Stack>
              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.5em" }}
              >
                <TextboxFormField
                  field={{
                    type: "textbox",
                    field: "givenName",
                    label: t("pages.patientSearch.givenNameRequired"),
                    disabled: disabled,
                  }}
                  fieldProps={fieldProps}
                />
              </Stack>

              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.5em" }}
              >
                <TextboxFormField
                  autoFocus={true}
                  field={{
                    type: "textbox",
                    field: "familyName",
                    label: t("pages.patientSearch.familyName"),
                    disabled: disabled,
                  }}
                  fieldProps={fieldProps}
                />
              </Stack>

              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.5em" }}
              >
                <TextboxFormField
                  field={{
                    type: "textbox",
                    field: "postcode",
                    label: t("pages.patientSearch.postalCode"),
                    disabled: disabled,
                  }}
                  fieldProps={fieldProps}
                />
              </Stack>

              <FormLabel label={t("pages.patientSearch.radioDobRequired")} />
              <RadioGroup
                data-testId="field:dateOfBirthIsExact"
                aria-labelledby="radio-buttons-group-label"
                name="radio-buttons-group"
                defaultValue={null}
                sx={{ mb: 3, display: "inline-block" }}
              >
                <Stack display="flex" flexDirection="column">
                  <FormControlLabel
                    control={
                      <Radio
                        sx={{ color: "primary.main" }}
                        disabled={disabled}
                      />
                    }
                    label={t("common.yes")}
                    value={true}
                    checked={formikProps.values.dateOfBirthIsExact === true}
                    onChange={(_event) => {
                      formikProps.setValues({
                        ...formikProps.values,
                        dateOfBirthIsExact: true,
                        dateOfBirthExact: null,
                        ageApprox: null,
                      });
                    }}
                  />

                  <FormControlLabel
                    control={
                      <Radio
                        sx={{ color: "primary.main" }}
                        disabled={disabled}
                      />
                    }
                    label={t("common.no")}
                    value={false}
                    checked={formikProps.values.dateOfBirthIsExact === false}
                    onChange={() => {
                      formikProps.setValues({
                        ...formikProps.values,
                        dateOfBirthIsExact: false,
                        dateOfBirthExact: null,
                        ageApprox: null,
                      });
                    }}
                  />
                </Stack>
                {formikProps.errors.dateOfBirthIsExact && (
                  <HelperText
                    errorMessage={
                      formikProps.errors.dateOfBirthIsExact as string
                    }
                  />
                )}
              </RadioGroup>

              {formikProps.values.dateOfBirthIsExact === true && (
                <DateFormField
                  field={{
                    type: "date",
                    field: "dateOfBirthExact",
                    label: t("pages.patientSearch.dateOfBirth"),
                    maximum: () => dayjs(),
                    disabled: disabled,
                  }}
                  fieldProps={fieldProps}
                />
              )}

              {formikProps.values.dateOfBirthIsExact === false && (
                <Box data-testId="field:ageApprox">
                  <Dropdown
                    label={t("pages.patientSearch.dropdownDateRangeLabel")}
                    errorMessage={formikProps.errors.ageApprox as string}
                    name="dropdown"
                    values={ageRange}
                    disabled={disabled}
                    selectedValue={formikProps.values.ageApprox ?? null}
                    onChange={(value) => {
                      formikProps.setValues({
                        ...formikProps.values,
                        ageApprox: value,
                      });
                    }}
                  />
                </Box>
              )}

              {
                // Extra children which will be added to the form.
                // Useful for injecting a submit button for testing.
                typeof children === "function"
                  ? children(formikProps)
                  : children
              }
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

// Values for the age range dropdown
const useAgeRange = () => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      (
        [
          {
            label: t("pages.patientSearch.dateRange.underAge18"),
            value: { min: null, max: 18 },
          },
          {
            label: t("pages.patientSearch.dateRange.age18To39"),
            value: { min: 18, max: 39 },
          },
          {
            label: t("pages.patientSearch.dateRange.age40To59"),
            value: { min: 40, max: 59 },
          },
          {
            label: t("pages.patientSearch.dateRange.age60To79"),
            value: { min: 60, max: 79 },
          },
          {
            label: t("pages.patientSearch.dateRange.overAge80"),
            value: { min: 80, max: null },
          },
          {
            label: t("pages.patientSearch.dateRange.unknown"),
            value: { min: null, max: null },
          },
        ] satisfies {
          label: string;
          value: { min: number | null; max: number | null } | null;
        }[]
      ).map((item) => {
        return {
          label: item.label as string,
          value: JSON.stringify(item.value),
        };
      }),
    [t],
  );
};
