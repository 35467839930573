import {
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  KeyboardDoubleArrowUp,
} from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";

import { PatientTimelineResponse } from "@aspire/common";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "~/api.js";
import {
  Banner,
  BannerList,
  Dropdown,
  PopupDialog,
  PopupDialogTitle,
  renderErrorToast,
  renderSuccessToast,
  TextField,
} from "~/components/design-system/index.js";
import { FormFooterSection } from "~/components/form/index.js";
import { CompactedPatientBanner } from "~/components/layout/index.js";
import { usePatientTimeline } from "~/hooks/apiCalls.js";
import { useGetNhsNumber } from "~/hooks/ExternalPatientLink/useGetNhsNumber.js";
import { useScreenDetection } from "~/hooks/ScreenDetection/useScreenDetection.js";
import { routeFns } from "~/routes.js";
import { PatientTimelineCard } from "../../PatientTimelineCard.js";

export function PatientUndoMergeDialog({
  closeUndoMergeDialog,
  reloadPatientTimeline,
}: {
  closeUndoMergeDialog: () => void;
  reloadPatientTimeline?: () => void;
}) {
  const { patientId } = useParams();
  const { patientTimeline } = usePatientTimeline({
    patientId: patientId,
  });

  return (
    patientTimeline && (
      <PatientUndoMergeDialogInner
        patientTimeline={patientTimeline}
        closeUndoMergeDialog={closeUndoMergeDialog}
        reloadPatientTimeline={reloadPatientTimeline}
      />
    )
  );
}

export function PatientUndoMergeDialogInner({
  patientTimeline,
  closeUndoMergeDialog,
  reloadPatientTimeline,
}: {
  patientTimeline: PatientTimelineResponse;
  closeUndoMergeDialog: () => void;
  reloadPatientTimeline?: () => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { isMobileView } = useScreenDetection();

  const [checkedFormItems, setCheckedFormItems] = useState<string[]>([]);
  const [reasonValueDropdown, setReasonValueDropdown] = useState<string>("");
  const [reasonDescription, setReasonDescription] = useState<
    string | undefined
  >(undefined);

  const isDropdownValueOther = reasonValueDropdown === "other";

  const showPatientItems =
    (isDropdownValueOther && reasonDescription) ||
    (!isDropdownValueOther && reasonValueDropdown);

  const reasonValues = [
    { label: "Merged by mistake", value: "mergedByMistake" },
    {
      label: "Other",
      value: "other",
    },
  ];

  const completedWorkItems = patientTimeline.completedWork.items;
  const inProgressWorkItems = patientTimeline.inProgressWork.items;
  const inProgressAdmissions = patientTimeline.inProgressWork?.admissions ?? [];

  const mergedTimelineForms = [
    ...completedWorkItems,
    ...inProgressWorkItems,
    ...inProgressAdmissions,
  ];

  const lastMergedPatient = patientTimeline.mergedPatientData.lastMergedPatient;
  if (lastMergedPatient === null) {
    throw new Error("lastMergedPatient is null");
  }

  const lastMergedDate = lastMergedPatient.lastMerged;

  const formsAfterLastMerged = mergedTimelineForms.filter((form) =>
    dayjs(form.created).isAfter(dayjs(lastMergedDate)),
  );

  // We need to predict what the NHS Numbers will be after the unmerge.
  const patientIdsToTreatAsRootPatients = [
    patientTimeline.patient.id,
    lastMergedPatient.id,
  ];
  const originalNhsNumber = useGetNhsNumber({
    patientId: patientTimeline.patient.id,
    patientIdsToTreatAsRootPatients,
  });
  const restoredNhsNumber = useGetNhsNumber({
    patientId: lastMergedPatient.id,
    patientIdsToTreatAsRootPatients,
  });

  const isRestricted = patientTimeline.patient.restricted;

  return (
    <PopupDialog open={true} onClose={closeUndoMergeDialog}>
      <PopupDialogTitle
        titleText={t("components.patientUnMergeDialog.subTitleText")}
        closeDialog={closeUndoMergeDialog}
      />
      <Box sx={{ mb: 2 }}>
        <Banner
          bannerType={BannerList.WARNING}
          title={t(
            "components.patientUnMergeDialog.unmergeBelowPatientsWarning",
          )}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Dropdown
          label={t("components.patientUnMergeDialog.whyUndoMerge")}
          selectedValue={reasonValueDropdown}
          name={"invite"}
          values={reasonValues}
          onChange={setReasonValueDropdown}
        />

        {reasonValueDropdown === "other" && (
          <TextField
            name={"reasonDescription"}
            label={t("components.patientMergeDialog.reasonDescriptionText")}
            onChange={setReasonDescription}
            value={reasonDescription ?? ""}
            useFullWidth={true}
          />
        )}
      </Box>
      <>
        <Box>
          <Box
            display="flex"
            flexDirection={isMobileView ? "column" : "row"} // Stack items on mobile
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              mb={isMobileView ? 2 : 0}
            >
              <Typography fontWeight="bold" sx={{ mb: 1 }}>
                {t("components.patientUnMergeDialog.patientWillBeRestored")}
              </Typography>
              <Box
                sx={{
                  border: `1px solid ${theme.palette.common.limeGreen}`,
                  borderRadius: "5px",
                }}
              >
                <CompactedPatientBanner
                  patient={{
                    ...lastMergedPatient,
                    restricted:
                      isRestricted && restoredNhsNumber ? true : false,
                  }}
                  nhsNumber={restoredNhsNumber ?? undefined}
                  isHidden
                  showUnknownMhaStatus
                />
              </Box>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mx={2}
            >
              {isMobileView ? (
                <>
                  <KeyboardDoubleArrowUp fontSize="large" />
                  <KeyboardDoubleArrowDown fontSize="large" />
                </>
              ) : (
                <>
                  <KeyboardDoubleArrowLeft fontSize="large" />
                  <KeyboardDoubleArrowRight fontSize="large" />
                </>
              )}
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              mb={isMobileView ? 2 : 0}
            >
              <Typography fontWeight="bold" sx={{ mb: 1 }}>
                Primary patient
              </Typography>
              <Box
                sx={{
                  border: `1px solid ${theme.palette.common.limeGreen}`,
                  borderRadius: "5px",
                }}
              >
                <CompactedPatientBanner
                  patient={{
                    ...patientTimeline.patient,
                    restricted:
                      isRestricted && originalNhsNumber ? true : false,
                  }}
                  nhsNumber={originalNhsNumber ?? undefined}
                  isHidden
                />
              </Box>
            </Box>
          </Box>
        </Box>

        {!!formsAfterLastMerged.length && (
          <>
            <Box sx={{ mb: 2 }}>
              <Banner
                bannerType={BannerList.WARNING}
                title={t("components.patientUnMergeDialog.formCreatedWarning")}
              />
            </Box>

            {formsAfterLastMerged.map((formContext) => (
              <Box sx={{ mt: 3 }}>
                <PatientTimelineCard
                  formContext={formContext}
                  disableCardClick={true}
                  showCheckBoxSelector={true}
                  checkedFormItems={checkedFormItems}
                  setCheckedFormItems={setCheckedFormItems}
                />
              </Box>
            ))}
          </>
        )}

        <FormFooterSection
          customFooterBackgroundColor={theme.palette.common.white}
          onSave={async () => {
            const result = await api.patients.unmerge({
              currentPatientId: patientTimeline.patient.id!,
              patientMergedBackId:
                patientTimeline.mergedPatientData.lastMergedPatient!.id,
              formContextIds: checkedFormItems,
              reason:
                reasonValues.find((item) => item.value === reasonValueDropdown)
                  ?.label ?? "",
              reasonDescription: reasonDescription,
            });

            if (result.status === 204) {
              const mostRecentlyMergedPatientName =
                patientTimeline.mergedPatientData.lastMergedPatient?.name;
              const currentPatientName = patientTimeline.patient.name;
              renderSuccessToast({
                message: `Successfully unmerged ${
                  mostRecentlyMergedPatientName?.given ?? ""
                } ${mostRecentlyMergedPatientName?.family ?? ""} from ${
                  currentPatientName?.given ?? ""
                } ${currentPatientName?.family ?? ""}`,
              });
              navigate(routeFns.patientHome(patientTimeline.patient.id));
              reloadPatientTimeline?.();
            } else {
              renderErrorToast({
                message: `Failed to merge - ${result.data.reason}`,
              });
            }
            closeUndoMergeDialog();
          }}
          performSaveActionOnFormSubmit
          saveLabel={t("buttonLabels.confirm")}
          saveVariant="contained"
          secondaryLeftButton={<></>}
          discardLabel={t("buttonLabels.close")}
          onCancel={closeUndoMergeDialog}
          disableSubmit={!showPatientItems}
        />
      </>
    </PopupDialog>
  );
}
