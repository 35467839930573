import { Box, FormControl, TextFieldProps, useTheme } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import { IconType } from "../../types/subText.js";
import { FormLabel } from "../FormLabel.js";
import { HelperText } from "../HelperText/HelperText.js";
import { ReadOnlyContent } from "../ReadOnlyContent/ReadOnlyContent.js";
import { TextField } from "../TextField/TextField.js";

const defaultProps: Partial<DateFieldProps> = {
  disabled: false,
  readOnly: false,
  isMobile: false,
  errorMessage: undefined,
  variant: "outlined",
  inputFormat: "DD-MM-YYYY",
};

export interface DateFieldProps {
  name: string;
  onChange: (e: Dayjs | null) => void;
  value: string | Dayjs | null;
  label?: string;
  subtext?: string;
  errorMessage?: string;
  subtextIcon?: IconType;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  disabled?: boolean;
  readOnly?: boolean;
  isMobile?: boolean;
  variant?: TextFieldProps["variant"];
  inputFormat?: string;
  defaultCalendarMonth?: Dayjs;
}

export const DatePickerIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7 18C14.05 18 13.5 17.775 13.05 17.325C12.6 16.875 12.375 16.3333 12.375 15.7C12.375 15.05 12.6 14.5 13.05 14.05C13.5 13.6 14.05 13.375 14.7 13.375C15.3333 13.375 15.875 13.6 16.325 14.05C16.775 14.5 17 15.05 17 15.7C17 16.3333 16.775 16.875 16.325 17.325C15.875 17.775 15.3333 18 14.7 18ZM5.3 21.5C4.8 21.5 4.375 21.325 4.025 20.975C3.675 20.625 3.5 20.2 3.5 19.7V6.3C3.5 5.8 3.675 5.375 4.025 5.025C4.375 4.675 4.8 4.5 5.3 4.5H6.7V3.15C6.7 2.93333 6.77067 2.75 6.912 2.6C7.054 2.45 7.23333 2.375 7.45 2.375C7.68333 2.375 7.87067 2.45 8.012 2.6C8.154 2.75 8.225 2.93333 8.225 3.15V4.5H15.8V3.125C15.8 2.925 15.875 2.75 16.025 2.6C16.175 2.45 16.35 2.375 16.55 2.375C16.7667 2.375 16.9457 2.45 17.087 2.6C17.229 2.75 17.3 2.925 17.3 3.125V4.5H18.7C19.2 4.5 19.625 4.675 19.975 5.025C20.325 5.375 20.5 5.8 20.5 6.3V19.7C20.5 20.2 20.325 20.625 19.975 20.975C19.625 21.325 19.2 21.5 18.7 21.5H5.3ZM5.3 20H18.7C18.7833 20 18.8543 19.971 18.913 19.913C18.971 19.8543 19 19.7833 19 19.7V10.3H5V19.7C5 19.7833 5.02933 19.8543 5.088 19.913C5.146 19.971 5.21667 20 5.3 20ZM5 8.8H19V6.3C19 6.21667 18.971 6.146 18.913 6.088C18.8543 6.02933 18.7833 6 18.7 6H5.3C5.21667 6 5.146 6.02933 5.088 6.088C5.02933 6.146 5 6.21667 5 6.3V8.8ZM5 8.8V6V8.8Z"
        fill={theme.palette.common.lightGray}
      />
    </svg>
  );
};

export const DateField = (props: DateFieldProps) => {
  const allProps: DateFieldProps = { ...defaultProps, ...props };
  const {
    name,
    label,
    subtext,
    onChange,
    value,
    inputFormat,
    errorMessage,
    subtextIcon,
    readOnly,
    minDate,
    variant,
    maxDate,
    disabled,
    isMobile,
    defaultCalendarMonth,
    ...rest
  } = allProps;

  const theme = useTheme();
  const { t } = useTranslation();

  if (readOnly) {
    return (
      <ReadOnlyContent
        label={label}
        content={[dayjs(value).format("DD-MM-YYYY")]}
        dense
        subtextIcon={subtextIcon!}
      />
    );
  }

  const iconColor = () => {
    if (disabled) return theme.palette.text.disabled;
    if (errorMessage) return theme.palette.error.main;
    return theme.palette.primary.main;
  };

  return (
    <Box
      component={Element}
      name={name}
      sx={{
        marginBottom: theme.spacing(3.5),
      }}
    >
      {label && <FormLabel label={label} error={!!errorMessage} />}
      <FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {isMobile ? (
            <MobileDatePicker
              defaultCalendarMonth={defaultCalendarMonth}
              showToolbar={true}
              inputFormat={inputFormat}
              value={value}
              components={{
                OpenPickerIcon: DatePickerIcon,
              }}
              minDate={minDate}
              maxDate={maxDate}
              onChange={onChange}
              disabled={disabled}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  {...rest}
                  noBottomMargin
                  muiTextFieldLabel={t("components.dateField.label")}
                  variant={variant}
                  sx={{
                    svg: { color: iconColor() },
                  }}
                />
              )}
            />
          ) : (
            <DesktopDatePicker
              defaultCalendarMonth={defaultCalendarMonth}
              showToolbar={true}
              inputFormat={inputFormat}
              value={value}
              components={{
                OpenPickerIcon: DatePickerIcon,
              }}
              minDate={minDate}
              maxDate={maxDate}
              onChange={onChange}
              InputAdornmentProps={{
                position: "end",
              }}
              disabled={disabled}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  {...rest}
                  noBottomMargin
                  muiTextFieldLabel={t("components.dateField.label")}
                  error={!!errorMessage}
                  variant={variant}
                  sx={{
                    svg: { color: iconColor() },
                  }}
                  useFullWidth={true}
                />
              )}
            />
          )}
        </LocalizationProvider>
      </FormControl>
      {!!errorMessage && (
        <HelperText subtext={subtext} errorMessage={errorMessage} />
      )}
    </Box>
  );
};
