import {
  getActiveMhaStatus,
  MhaDashboardDataParams,
  MhaDashboardDataResponse,
  MhaStatus,
  MHAStatusType,
  PatientStateTableRow,
  PatientStateWithContext,
} from "@aspire/common";
import { Info, Warning } from "@mui/icons-material";
import {
  Box,
  FormLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { NhsNumberSchema } from "@thalamos/common";
import dayjs, { Dayjs, ManipulateType } from "dayjs";
import { debounce } from "lodash-es";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { api } from "~/api.js";
import {
  Banner,
  BannerList,
  Button,
  ButtonIcon,
  Dropdown,
  LoadingSpinner,
  PopupDialog,
  PopupDialogTitle,
  renderErrorToast,
  renderWarningToast,
  TextField,
} from "~/components/design-system/index.js";
import { TooltipInfoBox } from "~/components/layout/index.js";
import { formatMhaStatus } from "~/components/util.js";
import { routeFns } from "~/routes.js";
import {
  triggerDownload,
  ukLocalFormatDate,
  ukLocalFormatDateTime,
} from "~/util.js";
import "../../@types/index.js";

export type MhaStatusDashboardProps = {
  data?: MhaDashboardDataResponse;
  isLoading: boolean;
  error?: string;
  showNhsNumber: boolean;
};

type ExpiryWarning = {
  showTime: boolean;
  warningThreshold: { unit: ManipulateType; value: number };
  criticalThreshold: { unit: ManipulateType; value: number };
};

const expiryThresholdTimes: { [key in MhaStatus]?: ExpiryWarning } = {
  [MhaStatus.Section17A]: {
    warningThreshold: { unit: "weeks", value: 8 },
    criticalThreshold: { unit: "weeks", value: 2 },
    showTime: false,
  },
  [MhaStatus.Section3]: {
    warningThreshold: { unit: "weeks", value: 8 },
    criticalThreshold: { unit: "weeks", value: 1 },
    showTime: false,
  },
  [MhaStatus.Section2]: {
    warningThreshold: { unit: "days", value: 7 },
    criticalThreshold: { unit: "days", value: 3 },
    showTime: false,
  },
  [MhaStatus.Section4]: {
    warningThreshold: { unit: "hours", value: 48 },
    criticalThreshold: { unit: "days", value: 1 },
    showTime: true,
  },
  [MhaStatus.Section5_2]: {
    warningThreshold: { unit: "hours", value: 48 },
    criticalThreshold: { unit: "days", value: 1 },
    showTime: true,
  },
  [MhaStatus.Section5_4]: {
    warningThreshold: { unit: "hours", value: 3 },
    criticalThreshold: { unit: "hours", value: 1 },
    showTime: true,
  },
};

function expiryCellContents(
  theme: Theme,
  state: PatientStateWithContext,
  activeStateType: MHAStatusType | undefined,
  expiryDateTime?: string,
) {
  const mhaStatus = state.state.mhaStatus.status;
  if (mhaStatus === MhaStatus.Incomplete)
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Warning sx={{ color: "orange", marginRight: "0.2em" }} />
        Unknown
      </Box>
    );

  if (mhaStatus === MhaStatus.Expired)
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Warning sx={{ color: "red", marginRight: "0.2em" }} />
        Unknown
      </Box>
    );

  if (!expiryDateTime) return "Unknown";

  const expiryThreshold = expiryThresholdTimes[mhaStatus];

  if (!expiryThreshold) return "Unknown";

  const expiryDate = dayjs(expiryDateTime);
  const now = dayjs();

  const formatFn = (d: Dayjs) =>
    expiryThreshold.showTime
      ? d.format("DD MMM YYYY HH:mm")
      : d.format("DD MMM YYYY");

  if (expiryDate.isBefore(now)) return `Expired (${formatFn(expiryDate)})`;

  const formattedDate = formatFn(expiryDate);

  const wasManuallyOverridden = state.expiryOverrideInfo;
  const message = wasManuallyOverridden
    ? `Expiry time manually overridden from ${ukLocalFormatDate(state.expiryOverrideInfo?.defaultExpiryDateTime!)}. Expiry time is end of day`
    : `Expiry time is end of day`;

  const expiryDateComponent = expiryThreshold.showTime ? (
    formattedDate
  ) : (
    <Tooltip title={message}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {formattedDate}{" "}
        {wasManuallyOverridden && (
          <Info
            sx={{
              color: theme.palette.primary.main,
              fontSize: "1.5rem",
              ml: 1,
            }}
          />
        )}
      </Box>
    </Tooltip>
  );

  if (
    activeStateType === "pending-renewal" ||
    activeStateType === "pending-cto"
  ) {
    return expiryDateComponent;
  }

  if (
    expiryDate.isBefore(
      now.add(
        expiryThreshold.criticalThreshold.value,
        expiryThreshold.criticalThreshold.unit,
      ),
    )
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Warning sx={{ color: "red", marginRight: "0.2em" }} />
        {expiryDateComponent}
      </Box>
    );
  }

  if (
    expiryDate.isBefore(
      now.add(
        expiryThreshold.warningThreshold.value,
        expiryThreshold.warningThreshold.unit,
      ),
    )
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Warning sx={{ color: "orange", marginRight: "0.2em" }} />
        {expiryDateComponent}
      </Box>
    );
  }

  return expiryDateComponent;
}

export function MhaStatusDashboardRow({
  index,
  row,
  showNhsNumber,
}: {
  row: PatientStateTableRow;
  index: number;
  showNhsNumber: boolean;
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  const activeState = getActiveMhaStatus(
    row.state.state,
    dayjs().toISOString(),
  );

  const isDeceasedPatient = ["formal", "informal"].includes(
    row.patientInfo.deathNotificationStatus ?? "",
  );

  return (
    <TableRow
      sx={{
        backgroundColor:
          index % 2 === 0 ? theme.palette.common.paleBlue : "white",
      }}
    >
      <TableCell>
        <Box display="flex" alignItems="center">
          <Link
            style={{
              textDecoration: "none",
            }}
            to={routeFns.patientHome(row.state.patientId)}
          >
            <Typography
              sx={{
                textDecoration: "none",
                fontSize: "0.9375rem",
                backgroundColor: "transparent",
                outline: "none",
                color: "blue",
              }}
              variant="subtitle1"
            >
              {`${row.patientInfo.name.family.toUpperCase()}, ${row.patientInfo.name.given}`}
            </Typography>
          </Link>
          {isDeceasedPatient && (
            <TooltipInfoBox
              title={
                "This patient has been marked as deceased in NHS national systems"
              }
              icon={<Info sx={{ fontSize: "1.5rem", ml: 1 }} />}
            />
          )}
        </Box>
      </TableCell>
      {showNhsNumber && (
        <TableCell>
          {NhsNumberSchema.catch(t("common.unknown")).parse(
            row.patientInfo.nhsNumber,
          )}
        </TableCell>
      )}
      <TableCell>
        {row.patientInfo.dateOfBirth
          ? dayjs(row.patientInfo.dateOfBirth).format("DD MMM YYYY")
          : "Unknown"}
      </TableCell>
      <TableCell>
        {formatMhaStatus(activeState.state, activeState.type)}
      </TableCell>
      <TableCell>
        {expiryCellContents(
          theme,
          row.state,
          activeState?.type,
          row.state.state.mhaStatus.expiryDateTime,
        )}
      </TableCell>
      <TableCell>{row.state.state.hospital?.name ?? "-"}</TableCell>
      <TableCell>
        <a href={routeFns.patientHome(row.state.patientId)}>
          <Button label="View" />
        </a>
      </TableCell>
    </TableRow>
  );
}

const reportDownloadReasons = [
  { value: "reconciliationWithEpr", label: "Reconciliation with EPR" },
  { value: "externalInspection", label: "External Inspection" },
  {
    value: "other",
    label: "Other",
  },
];

function ReportDownloadDialog(props: {
  params: Omit<MhaDashboardDataParams, "limit" | "offset">;
  patientSearchFilter: string;
  onClose: () => void;
}) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [reason, setReason] = useState<string | null>(null);
  const [reasonDescription, setReasonDescription] = useState<string | null>(
    null,
  );
  const theme = useTheme();

  return (
    <PopupDialog open={true} onClose={props.onClose}>
      <PopupDialogTitle
        closeDialog={props.onClose}
        titleText="Confirm MHA Status Report Download"
      />
      <Box>
        <Paper
          sx={{
            backgroundColor: theme.palette.grey[100],
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Included Statuses
                </TableCell>
                <TableCell>
                  {props.params.statuses
                    .map((status) => {
                      return formatMhaStatus(status, "active");
                    })
                    .join(", ")}
                </TableCell>{" "}
              </TableRow>
              <TableRow sx={{ fontWeight: "bold" }}>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Status Expiry Less Than
                </TableCell>
                <TableCell>
                  {props.params.expiryLessThan
                    ? props.params.expiryLessThan
                    : "Not specified"}
                </TableCell>
              </TableRow>
              <TableRow sx={{ fontWeight: "bold" }}>
                <TableCell sx={{ fontWeight: "bold" }}>Hospital Name</TableCell>
                <TableCell>
                  {props.params.organisationName
                    ? props.params.organisationName
                    : "Not specified"}
                </TableCell>
              </TableRow>
              <TableRow sx={{ fontWeight: "bold" }}>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Patient Name Filter
                </TableCell>
                <TableCell>
                  {props.patientSearchFilter
                    ? props.patientSearchFilter
                    : "Not specified"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Box sx={{ mt: "1em" }}>
          <FormLabel sx={{ fontWeight: "bold", marginTop: "1em" }}>
            Reason for download
          </FormLabel>
          <Select
            fullWidth
            value={reason}
            label="reason"
            onChange={(e) => {
              setReason(e.target.value);
              setReasonDescription(null);
            }}
          >
            {reportDownloadReasons.map((reason) => (
              <MenuItem value={reason.value}>{reason.label}</MenuItem>
            ))}
          </Select>
        </Box>
        {reason === "other" && (
          <Box sx={{ mt: "1em" }}>
            <TextField
              useFullWidth
              name="reasonDescription"
              label="Reason description"
              value={reasonDescription ?? ""}
              onChange={(e) => setReasonDescription(e)}
            />
          </Box>
        )}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1em",
          }}
        >
          <Button
            onClick={() => {
              props.onClose();
            }}
            label="Close"
            color="error"
          />
          <Button
            onClick={async () => {
              setIsDownloading(true);
              try {
                const response = await api.patientState.csvReport(
                  {
                    patientSearchFilter: props.patientSearchFilter,
                    reason: reason!,
                    reasonDescription: reasonDescription ?? undefined,
                  },
                  props.params.statuses,
                  props.params.expiryLessThan,
                  props.params.organisationName,
                );
                setIsDownloading(false);
                if (response.status === 200) {
                  if (response.data) {
                    triggerDownload(
                      `data:text/csv;base64,${response.data}`,
                      `emha-status-report-${ukLocalFormatDateTime(dayjs().toISOString())}.csv`,
                    );
                  } else {
                    renderWarningToast({
                      message:
                        "Report was empty - no matching rows found. Please check filters and try again",
                    });
                  }
                  props.onClose();
                } else {
                  renderErrorToast({ message: "Failed to download report" });
                }
              } catch (e) {
                setIsDownloading(false);
                renderErrorToast({ message: "Failed to download report" });
              }
            }}
            disabled={
              isDownloading ||
              !reason ||
              (reason === "other" && !reasonDescription)
            }
            label="Download"
          />
        </Box>
      </Box>
    </PopupDialog>
  );
}

export function MhaStatusSearchBarControls(props: {
  params: Omit<MhaDashboardDataParams, "limit" | "offset">;
  patientSearchFilter: string;
  setPatientSearchFilter: (value: string) => void;
  updateParams: (
    params: Omit<MhaDashboardDataParams, "limit" | "offset">,
  ) => void;
  title: string;
  subTitle: React.ReactNode;
  showReportDownload: boolean;
}) {
  const patientSearchFilter = props.patientSearchFilter;
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [organisationName, setOrganisationName] = useState(
    props.params.organisationName,
  );

  const updateFn = useCallback(debounce(props.updateParams, 500), []);

  useEffect(() => {
    updateFn({ ...props.params, patientSearchFilter, organisationName });
  }, [JSON.stringify(props.params), patientSearchFilter, organisationName]);

  const [showReportDownloadDialog, setShowReportDownloadDialog] =
    useState(false);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "1em",
          marginTop: "1em",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "0.75rem",
              color: theme.palette.common.blueGray,
            }}
          >
            {props.title}
          </Typography>
          <Typography
            sx={{ fontSize: "0.75rem", color: theme.palette.common.blueGray }}
          >
            {props.subTitle}
          </Typography>
        </Box>
        {props.showReportDownload && (
          <Button
            startIcon={ButtonIcon.download}
            label="Download as Spreadsheet"
            onClick={() => setShowReportDownloadDialog(true)}
            size="small"
            color="primary"
          />
        )}
        {showReportDownloadDialog && (
          <ReportDownloadDialog
            params={props.params}
            patientSearchFilter={patientSearchFilter}
            onClose={() => setShowReportDownloadDialog(false)}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            justifyContent: "flex-start",
            flex: 1,
          }}
        >
          <Box
            sx={{
              minWidth: isSmallScreen ? "100%" : "250px",
              marginBottom: isSmallScreen ? "1em" : 0,
              marginRight: isSmallScreen ? 0 : "1em",
            }}
          >
            <TextField
              useFullWidth
              name="patientSearchFilter"
              label="Filter by patient"
              value={patientSearchFilter}
              onChange={props.setPatientSearchFilter}
            />
          </Box>
          <Box
            sx={{
              minWidth: isSmallScreen ? "100%" : "250px",
              marginBottom: isSmallScreen ? "1em" : 0,
              mr: 2,
            }}
          >
            <TextField
              useFullWidth
              name="organisationName"
              label="Filter by hospital"
              value={organisationName}
              onChange={setOrganisationName}
            />
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
          }}
        >
          <Box
            sx={{
              marginRight: isSmallScreen ? 0 : "1em",
              marginBottom: isSmallScreen ? "1em" : 0,
              minWidth: "300px",
            }}
          >
            <Dropdown
              label="Detention Status"
              name="statuses"
              renderedValue={`${props.params.statuses.length} statuses selected`}
              multiple
              selectedValue={props.params.statuses}
              values={[
                { value: MhaStatus.Incomplete, label: "Unknown (Incomplete)" },
                { value: MhaStatus.Expired, label: "Unknown (Expired)" },
                { value: MhaStatus.Section5_2, label: "Section 5(2)" },
                { value: MhaStatus.Section5_4, label: "Section 5(4)" },
                { value: MhaStatus.Section2, label: "Section 2" },
                { value: MhaStatus.Section3, label: "Section 3" },
                { value: MhaStatus.Section4, label: "Section 4" },
                { value: MhaStatus.Section17A, label: "CTO" },
                { value: MhaStatus.NotDetained, label: "Not Detained" },
                { value: MhaStatus.Unknown, label: "Unknown" },
              ]}
              onChange={(newValues) => {
                // Don't allow us to end up with an empty list
                if (newValues.length > 0)
                  props.updateParams({ ...props.params, statuses: newValues });
              }}
            />
          </Box>
          <Box sx={{ minWidth: "300px" }}>
            <Dropdown
              label="Expiry Date"
              name="expiry"
              renderedValue={`${props.params.expiryLessThan ?? "-"}`}
              selectedValue={props.params.expiryLessThan}
              placeholder="Select a date range.."
              values={[
                { value: "", label: "-" },
                { value: "24 hours", label: "Expires in < 24 hours" },
                { value: "7 days", label: "Expires in < 7 days" },
                { value: "30 days", label: "Expires in < 30 days" },
                { value: "2 months", label: "Expires in < 2 months" },
              ]}
              onChange={(newValue) =>
                props.updateParams({
                  ...props.params,
                  expiryLessThan: newValue,
                })
              }
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export function MhaStatusDashboard(props: MhaStatusDashboardProps) {
  if (props.isLoading)
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "4em",
        }}
      >
        <LoadingSpinner />
      </Box>
    );

  // TODO: work this out
  if (props.error)
    return <Banner bannerType={BannerList.ERROR} title={props.error} />;

  // TODO: work this out
  if (!props.data?.rows.length)
    return <Banner bannerType={BannerList.INFO} title="No results found" />;

  const headerCellSx = { fontWeight: "bold" };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableCell sx={headerCellSx}>Patient Name</TableCell>
            {props.showNhsNumber && (
              <TableCell sx={headerCellSx}>NHS No.</TableCell>
            )}
            <TableCell sx={headerCellSx}>Born</TableCell>
            <TableCell sx={headerCellSx}>MHA Status</TableCell>
            <TableCell sx={headerCellSx}>Expiry Date / Time</TableCell>
            <TableCell sx={headerCellSx}>Hospital</TableCell>
            <TableCell sx={headerCellSx}></TableCell>
          </TableHead>
          <TableBody>
            {props.data.rows.map((row, index) => (
              <MhaStatusDashboardRow
                row={row}
                index={index}
                showNhsNumber={props.showNhsNumber}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
