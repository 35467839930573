import {
  formValidationErrorMessages,
  isoDateString,
  nameAndAddress,
  nonEmptyString,
  Patient,
} from "@aspire/common";
import {
  Box,
  DialogContent,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  useTheme,
} from "@mui/material";
import { NhsNumberSchema } from "@thalamos/common";
import { Formik } from "formik";
import { isEqual } from "lodash-es";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { InferType, mixed, object } from "yup";
import {
  Banner,
  BannerList,
  FormTitle,
  PopupDialog,
  renderErrorToast,
  renderSuccessToast,
  TextField,
} from "~/components/design-system/index.js";
import {
  BranchFormField,
  FormFooterSection,
  NameAddressFormField,
  TextboxFormField,
} from "~/components/form/index.js";
import { Container } from "~/components/layout/styleWrappers.js";
import { LoggedInUserContext } from "~/Contexts.js";
import { api } from "../../api.js";
import { routeFns } from "../../routes.js";
import { ConfirmationModal } from "../ConfirmationModal.js";
import { editPatientDialog } from "./PatientEditPage.js";

const patientCreateSchema = object({
  givenName: nonEmptyString
    .required("Please enter patient's forename")
    .max(255, formValidationErrorMessages.tooManyCharactersError),
  familyNameAndAddress: nameAndAddress({
    nameRequiredError: "Please enter patient's surname",
    addressRequiredError: "Please enter patient's home address",
  }),
  isPatientDateOfBirth: mixed()
    .nullable()
    .oneOf([true, false, null], "Please select an option")
    .default(null)
    .required("Please select an option"),
  dateOfBirth: isoDateString.default(null).when("isPatientDateOfBirth", {
    is: true,
    then: () => isoDateString.required("Please enter patient's date of birth"),
  }),
  deathNotificationStatus: mixed()
    .nullable()
    .oneOf(["informal", "formal", "removed", null], "Please select an option")
    .default(null)
    .optional(),
});

export type PatientCreateSchema = InferType<typeof patientCreateSchema>;

export type PatientCreateEditProps = {
  restricted?: boolean;
  nhsNumber?: string;
  refetchAndRedirect?: () => void;
  setShowUpdateSuccessDialog?: (v: boolean) => void;
  exitPatientCreate: () => void;
  initialState: PatientCreateSchema | null;
  mode: "create" | "edit" | editPatientDialog;
  expectedVersion?: number;
  existingPatientId?: string;
  existingPatient?: Patient;
};

const useSearchByNhsNumberEnabled = () => {
  const userContext = useContext(LoggedInUserContext);

  return (
    userContext?.user.sessionOrganisationConfiguration?.nhsNumberEnabled ??
    false
  );
};

export function PatientCreateEdit({
  refetchAndRedirect,
  setShowUpdateSuccessDialog,
  initialState,
  exitPatientCreate,
  mode,
  expectedVersion,
  existingPatientId,
  existingPatient,
  nhsNumber,
}: PatientCreateEditProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const patientId = useMemo(() => existingPatientId || v4(), []);

  const [confirmFn, setConfirmFn] = React.useState<{
    confirmFn: () => void;
    message: string;
  } | null>(null);

  const shouldShowNhsLabel = useSearchByNhsNumberEnabled();

  const [formChanged, setFormChanged] = React.useState(false);
  const [confirmInput, setConfirmInput] = React.useState("");
  const [isInputValid, setIsInputValid] = React.useState(false);
  const [hasConfirmed, setHasConfirmed] = React.useState(false);

  const handleFormChange = (values: PatientCreateSchema) => {
    let changedFieldsCount = 0;

    (Object.keys(values) as (keyof PatientCreateSchema)[]).forEach((key) => {
      const newValue = values[key];
      const oldValue = initialState?.[key];
      if (!isEqual(newValue, oldValue)) {
        changedFieldsCount++;
      }
    });

    setFormChanged(changedFieldsCount > 1);
  };

  const handleClose = () => {
    setFormChanged(false);
    navigate(routeFns.patientHome(patientId));
  };

  return (
    <>
      <FormTitle
        useReducedTopPadding={true}
        hasTitleBottomMargin={false}
        titleText={
          mode === "create"
            ? t("pages.patientSearch.patientCreate.title")
            : `Edit patient ${initialState?.givenName} ${initialState?.familyNameAndAddress?.name} ${nhsNumber && shouldShowNhsLabel ? `(${NhsNumberSchema.catch(t("common.unknown")).parse(nhsNumber)})` : ""}`
        }
      />

      <PopupDialog
        open={formChanged && !hasConfirmed && mode !== "create"}
        onClose={() => {}}
        maxWidth="sm"
        fullScreen={false}
        hasPadding={true}
      >
        <DialogContent>
          <Banner
            sx={{ mb: 2 }}
            title={t("pages.patientEditCreate.confirmBanner", {
              patientSearchRoute: routeFns.patientSearch(),
            })}
            bannerType={BannerList.WARNING}
          />
          <TextField
            name="confirmInput"
            value={confirmInput}
            onChange={(val) => {
              setConfirmInput(val);
              if (val.toLowerCase() === "confirm") {
                setIsInputValid(true);
                // TODO - Add an alert to datadog?
              } else {
                setIsInputValid(false);
              }
            }}
            useFullWidth={true}
          />
        </DialogContent>
        <FormFooterSection
          customFooterBackgroundColor="common.white"
          saveLabel={t("buttonLabels.confirm")}
          disableSubmit={!isInputValid}
          onSave={() => {
            setFormChanged(false);
            setHasConfirmed(true);
          }}
          discardLabel={t("buttonLabels.close")}
          onCancel={handleClose}
        />
      </PopupDialog>

      {existingPatient?.restricted && (
        <Banner
          sx={{ mb: 2 }}
          bannerType={BannerList.ERROR}
          title="Patient is marked as restricted in NHS National Systems. Address details are sensitive and not synchronised to/from other systems"
        />
      )}
      {mode !== "create" && (
        <Banner
          sx={{ mb: 2 }}
          bannerType={BannerList.WARNING}
          title={t("pages.patientEditCreate.editPatientInformation", {
            givenName:
              initialState?.givenName && initialState?.givenName.length > 0
                ? initialState?.givenName
                : t("common.unknown").toUpperCase(),
            familyName:
              initialState?.familyNameAndAddress &&
              initialState?.familyNameAndAddress?.name
                ? initialState?.familyNameAndAddress?.name
                : t("common.unknown").toUpperCase(),
            patientSearchRoute: routeFns.patientSearch(),
          })}
        />
      )}

      {confirmFn && (
        <ConfirmationModal
          message={confirmFn.message}
          confirmFn={confirmFn.confirmFn}
          closeFn={() => setConfirmFn(null)}
        />
      )}

      <Formik<any>
        validationSchema={patientCreateSchema}
        initialValues={{
          givenName: initialState?.givenName ?? null,
          familyNameAndAddress: {
            name: initialState?.familyNameAndAddress?.name ?? null,
            postalCode: initialState?.familyNameAndAddress?.postalCode ?? null,
            address: initialState?.familyNameAndAddress?.address ?? null,
            isConfirmed:
              initialState?.familyNameAndAddress?.isConfirmed ?? false,
          },
          isPatientDateOfBirth:
            typeof initialState?.isPatientDateOfBirth === "boolean"
              ? initialState?.isPatientDateOfBirth
              : null,
          dateOfBirth: initialState?.dateOfBirth ?? null,
          deathNotificationStatus:
            existingPatient?.deathNotificationStatus ?? null,
        }}
        onSubmit={async (values: any) => {
          if (mode === "create") {
            const result = await api.patients.create(patientId, {
              type: "demographics",
              demographics: {
                name: {
                  given: values.givenName,
                  family: values.familyNameAndAddress.name,
                },
                address: {
                  address: values.familyNameAndAddress.address,
                  postalCode: values.familyNameAndAddress.postalCode,
                },
                dateOfBirth: values.dateOfBirth,
              },
            });

            if (result.status !== 200) {
              renderErrorToast({
                message: t("pages.patientEditCreate.createError"),
              });
            } else {
              renderSuccessToast({
                message: t("pages.patientEditCreate.createSuccess"),
              });
              navigate(routeFns.patientHome(patientId));
            }
          } else {
            setConfirmFn({
              message: t(`pages.patientEditCreate.editPatientConfirmation`, {
                givenName: initialState?.givenName,
                familyName: initialState?.familyNameAndAddress?.name,
              }),
              confirmFn: async () => {
                const result = await api.patients.update(patientId, {
                  expectedVersion: expectedVersion!,
                  type: "demographics",
                  demographics: {
                    name: {
                      given: values.givenName,
                      family: values.familyNameAndAddress.name,
                    },
                    address: {
                      address: values.familyNameAndAddress.address,
                      postalCode: values.familyNameAndAddress.postalCode,
                    },
                    dateOfBirth: values.dateOfBirth,
                  },
                  deathNotificationStatus: values.deathNotificationStatus,
                });

                if (result.status !== 200) {
                  renderErrorToast({
                    message: t("pages.patientEditCreate.updateError"),
                  });
                } else {
                  renderSuccessToast({
                    message: t("pages.patientEditCreate.updateSuccess"),
                  });
                  mode === "editPatientDialog"
                    ? refetchAndRedirect && refetchAndRedirect()
                    : setShowUpdateSuccessDialog &&
                      setShowUpdateSuccessDialog(true);
                }
                setConfirmFn(null);
              },
            });
          }
        }}
      >
        {({
          values,
          setValues,
          errors,
          touched,
          setFieldTouched,
          handleBlur,
          setValues: formikSetValues,
          submitForm,
          isSubmitting,
        }) => {
          const fieldProps = {
            validationSchema: patientCreateSchema,
            context: {},
            values,
            errors,
            handleBlur,
            setFieldTouched,
            touched,
            setValues,
            onChange: mode !== "create" && handleFormChange(values),
          };
          return (
            <>
              <Container>
                <Box width="100%" sx={{ mb: 1 }}>
                  <TextboxFormField
                    field={{
                      type: "textbox",
                      field: "givenName",
                      label: t("pages.patientSearch.patientCreate.givenName"),
                    }}
                    fieldProps={fieldProps}
                  />
                </Box>
                <Box width="100%" sx={{ mb: 2 }}>
                  <NameAddressFormField
                    field={{
                      type: "name-address",
                      field: "familyNameAndAddress",
                      disableEmail: true,
                      nameLabel: t(
                        "pages.patientSearch.patientCreate.familyNameAndAddress.nameLabel",
                      ),
                      addressLabel: t(
                        "pages.patientSearch.patientCreate.familyNameAndAddress.addressLabel",
                      ),
                    }}
                    fieldProps={fieldProps}
                  />
                </Box>
                <Box width="100%">
                  <BranchFormField
                    field={{
                      type: "branch",
                      field: "isPatientDateOfBirth",
                      label: t("pages.patientSearch.patientCreate.isDOBlabel"),
                      branches: [
                        {
                          label: t("common.yes"),
                          fieldValue: true,
                          fields: [
                            {
                              type: "date",
                              field: "dateOfBirth",
                              label: t(
                                "pages.patientSearch.patientCreate.dateOfBirth",
                              ),
                            },
                          ],
                          fieldsOwned: ["dateOfBirth"],
                        },
                        {
                          label: t("common.no"),
                          fieldValue: false,
                          fields: [],
                          fieldsOwned: [],
                        },
                      ],
                    }}
                    fieldProps={fieldProps}
                  />
                </Box>
                {existingPatient?.deathNotificationStatus && (
                  <Box width="100%">
                    <FormLabel
                      sx={{ fontWeight: "bold", color: "text.primary" }}
                      id="death-notification-status-radio-buttons-group-label"
                    >
                      {t(
                        "pages.patientSearch.patientCreate.deathNotificationStatusLabel",
                      )}
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="death-notification-status-radio-buttons-group-label"
                      name="radio-buttons-group"
                      defaultValue={null}
                      value={values.deathNotificationStatus}
                      onChange={(event) => {
                        setValues({
                          ...values,
                          deathNotificationStatus: event.target.value,
                        });
                      }}
                    >
                      <Stack display="flex" flexDirection="column">
                        <FormControlLabel
                          control={<Radio sx={{ color: "primary.main" }} />}
                          label={t(
                            "pages.patientSearch.patientCreate.deathNotificationStatusOptions.informal",
                          )}
                          value={"informal"}
                        />
                        <FormControlLabel
                          control={<Radio sx={{ color: "primary.main" }} />}
                          label={t(
                            "pages.patientSearch.patientCreate.deathNotificationStatusOptions.formal",
                          )}
                          value={"formal"}
                        />
                        <FormControlLabel
                          control={<Radio sx={{ color: "primary.main" }} />}
                          label={t(
                            "pages.patientSearch.patientCreate.deathNotificationStatusOptions.deathStatusRemoved",
                          )}
                          value={"removed"}
                        />
                      </Stack>
                    </RadioGroup>
                  </Box>
                )}
              </Container>
              <FormFooterSection
                customFooterBackgroundColor={theme.palette.common.white}
                onSave={submitForm}
                saveLabel={
                  mode === "create"
                    ? t("buttonLabels.createPatient")
                    : t("buttonLabels.updatePatient")
                }
                saveVariant="contained"
                discardLabel={
                  mode === "create"
                    ? t("buttonLabels.backSearch")
                    : mode === "editPatientDialog"
                      ? t("buttonLabels.close")
                      : t("buttonLabels.backPatient")
                }
                onCancel={exitPatientCreate}
                disableDiscard={isSubmitting}
                disableSubmit={isSubmitting}
                minHeight
              />
            </>
          );
        }}
      </Formik>
    </>
  );
}
