import {
  GetFormDraftResponse,
  SelectedFormsObject,
  ValidationOutcome,
  canLaunchForm,
  getBaseFormTemplate,
  getFullFormName,
  isGuestUserSession,
} from "@aspire/common";
import { DialogActions } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { InferType } from "yup";
import {
  Banner,
  BannerList,
  Button,
  LoadingSpinner,
  PopupDialog,
  PopupDialogTitle,
  Stepper,
  renderErrorToast,
} from "~/components/design-system/index.js";
import {
  ConfirmDialog,
  FormFooterSection,
  SignatureDialog,
} from "~/components/form/index.js";
import {
  NewFormAssigneeDialog,
  NewResultWithoutAccreditation,
  newFormAssigneeData,
} from "~/components/FormAssignee/NewFormAssigneeDialog.js";
import { PatientBanner } from "~/components/layout/index.js";
import { useGetNhsNumber } from "~/hooks/ExternalPatientLink/useGetNhsNumber.js";
import {
  actions,
  bannerLogic,
  getTemplate,
} from "~/pages/FormProgressPage/helpers/RequestWorkItemDialog.js";
import { api, apiHooks } from "../../api.js";
import { MultiPagePdf } from "../../components/MultiPagePdf.js";
import { LoggedInUserContext } from "../../Contexts.js";
import { usePatientTimeline } from "../../hooks/apiCalls.js";
import { routeFns } from "../../routes.js";
import { DefaultPageProps } from "../defaultProps.js";
import { FormSupportingDocumentsSelector } from "./FormSupportingDocumentsSelector.js";
import { onHappyWithSignature } from "./helpers/onHappyWithSignature.js";

// This page supports three views/modes:
//   - "signature"          This is the primary mode which allows the user to either draw or upload a signature to sign the form
//   - "confirmation"       This is used instead of the signature view for ACD forms which do not require a signature
//   - "document-selection" This is used *in addition* to the signature mode for forms that require "supporting documents"
//                          to be attached to them (for example, Application for Detention" forms (A6/A6/A10), which require
//                          Medical Recommendations to be attached to them). It is shown prior to the signature screen.
type SigningMode = "confirmation" | "signature" | "document-selection";

function FormDraftSignPageInner({
  formDraft,
  pdfData,
}: {
  formDraft: GetFormDraftResponse<any>;
  pdfData: string;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useContext(LoggedInUserContext)!.user;
  const isGuest = isGuestUserSession(user);

  // Signing state
  const formId = formDraft.formId;
  const [signing, setSigning] = useState(false);
  const [saveDefaultSignature, setSaveDefaultSignature] = useState(false);

  const [saveSignature, setSaveSignature] = useState<string | null | undefined>(
    null,
  );

  // Load patient (+ watch for changes via pusher)
  const patientHook = apiHooks.patients.get(formDraft.patientId);
  const [{ data: patient, loading: patientLoading }] = patientHook;

  const { patientTimeline, reloadPatientTimeline } = usePatientTimeline({
    patientId: formDraft?.patientId,
  });

  const nhsNumber = useGetNhsNumber({ patientId: formDraft.patientId });

  // Template data
  const isAmend = formDraft.formVersion > 1;
  const template = getBaseFormTemplate(formDraft)!;

  const signingRequirements = template.parts[formDraft.part - 1].signing;
  const requiresSignature = !isAmend && !signingRequirements.confirmationOnly;
  const linkableForms = signingRequirements.linkableForms;
  const requiresSupportingForms =
    !isAmend && !!linkableForms?.confirmAfterCompletion;

  const fullFormName = getFullFormName(template);

  const [searchResults, setSearchResults] = React.useState<
    NewResultWithoutAccreditation[]
  >([]);

  const [shouldShowSendDialog, setShouldShowSendDialog] =
    useState<boolean>(false);

  const [shouldShowWarningDialog, setShouldShowWarningDialog] =
    useState<boolean>(false);

  const signingRequirementShowSendDialog =
    signingRequirements.userOrTeamRequirement.requireSendingWhenSigning;

  const showSendDialog =
    shouldShowSendDialog && signingRequirementShowSendDialog;

  // Signing mode
  let initialMode: SigningMode = "confirmation";
  if (requiresSignature) initialMode = "signature";
  if (requiresSupportingForms) initialMode = "document-selection";
  const [signingMode, setSigningMode] = useState<SigningMode>(initialMode);
  const qs = new URLSearchParams(window.location.search);
  const requestType = qs.get("requestType");

  const availableActions = actions(formDraft.formContext, user);

  // Supporting documents state
  const [supportingFormsProvided, setSupportingFormsProvided] =
    useState<boolean>(false);
  const initiallySelectedForms = formDraft.linkedForms
    .filter((f) => f.reason === "supported-by")
    .map((f) => ({ id: f.id, templateId: f.formTemplate.id }));

  const [selectedForms, setSelectedForms] = useState<SelectedFormsObject[]>(
    initiallySelectedForms,
  );

  const [notSelectedReason, setNotSelectedReason] = useState<string>("");
  const [validationOutcome, setValidationOutcome] = useState<
    ValidationOutcome[] | null
  >(null);

  const { guestAccreditationOptions } = bannerLogic(
    user,
    formDraft.formContext,
    fullFormName,
    template!,
    requestType!,
  );

  const filteredSelectedForms = !supportingFormsProvided ? selectedForms : [];

  const canContinueOrStartForm = canLaunchForm({
    linkableForms,
    supportingFormsProvided,
    notSelectedReason,
    validationOutcome,
    requiresSupportingForms,
  });

  // Don't render anything until the patient has loaded
  if (!patient) {
    if (patientLoading) {
      return <LoadingSpinner />;
    } else {
      return <div>Error - could not find patient.</div>;
    }
  }

  return (
    <>
      <PatientBanner
        patient={patient}
        nhsNumber={nhsNumber ?? undefined}
        patientTimeline={patientTimeline}
        reloadPatientTimeline={reloadPatientTimeline}
      />

      {signingMode === "document-selection" && (
        <>
          <FormSupportingDocumentsSelector
            formContext={formDraft.formContext}
            linkableForms={linkableForms}
            selectedForms={filteredSelectedForms}
            setSelectedForms={setSelectedForms}
            notSelectedReason={notSelectedReason}
            setNotSelectedReason={setNotSelectedReason}
            supportingFormsProvided={supportingFormsProvided}
            setSupportingFormsProvided={setSupportingFormsProvided}
            setValidationOutcome={setValidationOutcome}
          />
          <FormFooterSection
            isForcedSticky={true}
            isSticky
            disableSubmit={!canContinueOrStartForm}
            onCancel={() =>
              navigate(
                routeFns.formDraftsComplete(formDraft.id, formDraft.patientId),
              )
            }
            onSave={() => setSigningMode("signature")}
            discardLabel={t("buttonLabels.goBack")}
            saveLabel={t("buttonLabels.preview")}
          />
        </>
      )}

      {signingMode !== "document-selection" && (
        <>
          {signingMode === "confirmation" && (
            <ConfirmDialog
              closeDialog={() => setSigning(false)}
              isOpen={signing}
              formTitleSubtitleText={
                isAmend
                  ? t(
                      "pages.formDraftPreviewPage.popupDialog.formTitleSubtitleTextAmends",
                    )
                  : t(
                      "pages.formDraftPreviewPage.popupDialog.formTitleSubtitleText",
                    )
              }
              warningMessage={
                isAmend
                  ? t(
                      "pages.formDraftPreviewPage.popupDialog.warningMessageAmends",
                    )
                  : t("pages.formDraftPreviewPage.popupDialog.warningMessage")
              }
              onConfirm={() =>
                onHappyWithSignature({
                  signature: undefined,
                  requiresSupportingForms,
                  allSelectedForms: filteredSelectedForms.map((s) => s.id),
                  reason: notSelectedReason,
                  formId,
                  navigate,
                  formDraft,
                  template,
                  requiresSignature,
                  saveDefaultSignature,
                  user,
                })
              }
            />
          )}
          {signingMode === "signature" && signing && (
            <SignatureDialog
              onClose={() => setSigning(false)}
              onHappyWithSignature={(signature) => {
                // This will show the send dialog if the specific form
                // (check the form builder for the requireSendingWhenSigning check) requires it
                if (signingRequirementShowSendDialog) {
                  setSaveSignature(signature);
                  return setShouldShowSendDialog(true);
                } else {
                  return onHappyWithSignature({
                    signature,
                    requiresSupportingForms,
                    allSelectedForms: selectedForms.map((s) => s.id),
                    reason: notSelectedReason,
                    formId,
                    navigate,
                    formDraft,
                    template,
                    requiresSignature,
                    saveDefaultSignature,
                    user,
                  });
                }
              }}
              userDefaultSignature={user.defaultSignature}
              confirmButtonLabel={t("buttonLabels.confirm")}
              clearButtonLabel={t("buttonLabels.clear")}
              saveDefaultSignature={saveDefaultSignature}
              setSaveDefaultSignature={setSaveDefaultSignature}
              allowUseOfDefaultSignature={!isGuest}
            />
          )}

          {shouldShowWarningDialog && (
            <PopupDialog maxWidth="sm" open={true} testId="request-sign-dialog">
              <PopupDialogTitle
                titleText={
                  formDraft.formContext.type === "admission"
                    ? t("common.sendInvite")
                    : t("common.sendForm")
                }
                closeDialog={() => {
                  setShouldShowSendDialog(false);
                  setShouldShowWarningDialog(true);
                  navigate(
                    routeFns.formDraftsSign(formDraft.id, formDraft.patientId),
                  );
                }}
              />
              <Banner
                sx={{ mb: 4 }}
                bannerType={BannerList.WARNING}
                title="WARNING"
                body={[
                  "Please complete process by sending form to recipients. Changes will be lost if you exit the process now",
                ]}
              />

              <DialogActions sx={{ display: "flex" }}>
                <Button
                  label="Exit"
                  testId="close-button"
                  onClick={() => {
                    setShouldShowWarningDialog(false);
                    setSaveSignature(null);
                    navigate(
                      routeFns.formDraftsSign(
                        formDraft.id,
                        formDraft.patientId,
                      ),
                    );
                  }}
                  variant="outlined"
                />
                <Button
                  label="Back"
                  testId="confirm-button"
                  onClick={() => {
                    setShouldShowWarningDialog(false);
                    setShouldShowSendDialog(true);
                  }}
                />
              </DialogActions>
            </PopupDialog>
          )}

          {showSendDialog && (
            <PopupDialog maxWidth="lg" open={true} testId="request-sign-dialog">
              <PopupDialogTitle
                titleText={
                  formDraft.formContext.type === "admission"
                    ? t("common.sendInvite")
                    : t("common.sendForm")
                }
                closeDialog={() => {
                  setShouldShowSendDialog(false);
                  setShouldShowWarningDialog(true);
                  navigate(
                    routeFns.formDraftsSign(formDraft.id, formDraft.patientId),
                  );
                }}
              />
              <Banner
                sx={{ mb: 4 }}
                body={[
                  `You have successfully signed part 1. Send ${fullFormName} to another team to complete part ${formDraft.part + 1}`,
                  `Part ${formDraft.part + 1} to be completed by someone acting on behalf of the responsible hospital. <b> Please complete the process by sending form to recipients. Changes will be lost if you do not send to recipients now.</b>`,
                ]}
              />
              <NewFormAssigneeDialog
                formId={formId}
                formContext={formDraft.formContext}
                reloadFormContext={() => {}}
                availableActions={availableActions}
                searchAssignmentOptions={async (formDetails, searchValue) => {
                  return api.formTemplates.searchAssignmentOptions({
                    id: formDetails.template.id,
                    version: formDetails.template.version,
                    query: searchValue,
                    part: formDetails.part,
                  });
                }}
                formDetails={getTemplate(formDraft.formContext)}
                userId={user.id}
                searchResults={searchResults}
                setSearchResults={setSearchResults}
                onSave={async (
                  formData: InferType<typeof newFormAssigneeData>,
                ) => {}}
                navigate={navigate}
                guestAccreditationOptions={guestAccreditationOptions}
                signForm={async () => {
                  if (saveSignature) {
                    const signDraftRequest = {
                      draftId: formDraft.id,
                      signatureBase64: saveSignature,
                    };

                    const result = await api.forms.sign(
                      formId,
                      signDraftRequest,
                    );

                    if (result.status === 204) {
                      if (saveDefaultSignature && !user.defaultSignature)
                        await api.users.update(user.id, {
                          defaultSignature: saveSignature,
                        });
                    } else {
                      renderErrorToast({ message: "Signing failed" });
                    }
                  }
                }}
              />
            </PopupDialog>
          )}

          <Stepper
            steps={[
              t("pages.formDraftFlow.standardFlow.stepOne"),
              t("pages.formDraftFlow.standardFlow.stepTwo"),
              t("pages.formDraftFlow.standardFlow.stepThree"),
            ]}
            activeStep={1}
          />
          <MultiPagePdf data={pdfData} />
          <FormFooterSection
            isForcedSticky={true}
            isSticky
            disableSubmit={!canContinueOrStartForm}
            onCancel={() => {
              if (requiresSupportingForms) {
                setSigningMode("document-selection");
              } else {
                navigate(
                  routeFns.formDraftsComplete(
                    formDraft.id,
                    formDraft.patientId,
                  ),
                );
              }
            }}
            onSave={() => setSigning(true)}
            discardLabel={t("buttonLabels.goBack")}
            saveLabel={
              requiresSignature
                ? t("buttonLabels.sign")
                : t("buttonLabels.confirm")
            }
          />
        </>
      )}
    </>
  );
}

export function FormDraftSignPage({}: DefaultPageProps) {
  let { formDraftId, patientId } = useParams();

  const [{ data: formDraft, loading: formDraftLoading }] = apiHooks.drafts.get(
    formDraftId!,
  );

  const [{ data: formDraftPdf, loading: formDraftPdfLoading }] =
    apiHooks.drafts.getPdf(formDraftId!, false);

  return formDraftLoading || formDraftPdfLoading ? (
    <></>
  ) : !formDraft?.id ? (
    // TODO: generic 404 component
    <div>Oops - couldn't find that draft!</div>
  ) : (
    <FormDraftSignPageInner formDraft={formDraft} pdfData={formDraftPdf!} />
  );
}
