import React from "react";

import { PatientCreateEdit } from "../PatientIndex/PatientCreateEdit.js";
import { ValidationSchema } from "./CollectCriteriaDemographics.js";

export type CreatePatientProps = {
  initialValues?: ValidationSchema;
  onCancel: () => void;
};

export const CreatePatient = ({
  initialValues,
  onCancel,
}: CreatePatientProps) => {
  return (
    <PatientCreateEdit
      mode={"create"}
      exitPatientCreate={onCancel}
      initialState={
        initialValues === undefined
          ? null
          : {
              givenName: initialValues.givenName ?? "",
              familyNameAndAddress: {
                name: initialValues.familyName ?? "",
                address: "",
                isConfirmed: false,
              },
              isPatientDateOfBirth: initialValues.dateOfBirthIsExact === true,
              dateOfBirth: initialValues.dateOfBirthExact,
              deathNotificationStatus: null,
            }
      }
    />
  );
};
